import React, { useEffect, useState } from "react";
import { Card, CardHeader, Table, Container, Row, Button } from "reactstrap";
import Header from "components/Headers/Header.js";
import { getAllEmails } from "services/api";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { trimContent } from "services/help";
import { EmailModal } from "views/modal/EmailModal";
import { remove_p_br_span_Tags } from "services/help";

const EmailTemplates = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [templateContent, setTemplateContent] = useState("");
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState();
  const [loader, setLoader] = useState(false);

  const { navigate } = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const output = await getAllEmails();
        console.log("get email output : ", output);
        // return;
        if (!localStorage.getItem("accessToken")) {
          console.log("Logout from header");
          navigate("/auth/index");

          Swal.fire({
            position: "center",
            icon: "error",
            title: "Admin token expire",
            showConfirmButton: false,
            timer: 1500,
          });
        }

        setEmailTemplates(output?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle errors here, such as displaying an error message or setting a state variable
      }
    };

    fetchData(); // Call the fetchData function
  }, [loader]);

  const toggle = () => {
    setLoader((prev) => !prev);
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row">
                  <h3 className="mb-0">Email Templates Management</h3>
                </div>
              </CardHeader>
              <div className="table-responsive">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr style={{ maxWidth: "10wh" }}>
                      <th scope="col">ID</th>
                      <th scope="col">Title</th>
                      <th scope="col">Email Id</th>
                      <th scope="col">Subject</th>
                      <th scope="col">Content</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {emailTemplates.map((template, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{trimContent(template.title, 20)}</td>
                        <td>{template.emailId}</td>
                        <td>{trimContent(template.subject, 30)}</td>
                        <td>
                          {trimContent(
                            remove_p_br_span_Tags(template.content),
                            70
                          )}
                        </td>
                        <td>
                          <Button
                            color="primary"
                            size="sm"
                            className="mr-2"
                            onClick={() => {
                              setSelectedEmail(template);
                              console.log("Email : ", template);
                              toggle();
                            }}
                          >
                            Edit
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
      {/* Edit Template Modal */}
      <EmailModal email={selectedEmail} isOpen={isOpen} toggle={toggle} />
    </>
  );
};

export default EmailTemplates;
