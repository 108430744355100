import { useEffect, useState } from "react";
import {
  Badge,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  InputGroup,
  InputGroupAddon,
  Button,
  Input,
  Row,
  Card,
  Container,
} from "reactstrap";
import { getAllBuyTx } from "services/api";
import { formatDate } from "services/help";
import { trimAddress } from "services/help";
import { handleCSVDownload } from "services/help";
import { handleExcelDownload } from "services/help";
import { swalFire } from "services/help";
import moment from "moment";

const FullBuyTransactionTable = () => {
  const [buyData, setBuyData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredTx, setFilteredTx] = useState([]);
  const [initialDate, setInitialDate] = useState({ start: "", end: "" });
  const [filterDate, setFilterDate] = useState({ start: "", end: "" });
  const [buyMethod, setBuyMethod] = useState("");
  const [pageNumber, setPageNumber] = useState(1);

  const desiredFields = [
    "username",
    "createdAt",
    "buyMethod",
    "depositWallet",
    "amount",
    "transactionHash",
    "status",
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        let data = {
          buyMethod: buyMethod,
          page: pageNumber,
          limit: 10,
        };

        if (filterDate.start !== "" && filterDate.end !== "") {
          data.dateRange = {
            start: moment(filterDate.start).startOf("day").toISOString(),
            end: moment(filterDate.end).endOf("day").toISOString(),
          };
        }

        const output = await getAllBuyTx(data);
        console.log("all transactions : ", output?.data?.data?.docs);
        setBuyData(output);
        const filtered = output?.data?.data.docs.filter(
          (user) =>
            user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.buyMethod.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.depositWallet
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            user.transactionHash
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            user.token.toString() === searchTerm
        );
        setFilteredTx(filtered);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [buyMethod, filterDate, pageNumber, searchTerm]);

  const renderTableRows = () => {
    return filteredTx && filteredTx.length > 0 ? (
      filteredTx?.map((item, index) => (
        <tr key={index}>
          <th scope="row">{index + 1}</th>
          <th scope="row">{item.username}</th>
          <td>{formatDate(item.createdAt)}</td>
          <td>
            {item.amount} {item.buyMethod}
          </td>
          <td>{trimAddress(item.depositWallet)}</td>
          <td>{item.token} CUCC</td>
          <td>{trimAddress(item.transactionHash)}</td>
          <td>
            <Badge
              color=""
              className="badge-dot mr-4"
              style={{ paddingTop: "0", paddingBottom: "0" }}
            >
              <i
                className={
                  item?.status !== "pending" ? "bg-success" : "bg-warning"
                }
              />
              {item?.status !== "pending" ? "completed" : "pending"}
            </Badge>
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan="9" className="text-center">
          No data available
        </td>
      </tr>
    );
  };

  return (
    <>
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row">
                  <h3 className="mb-0">Transactions Management</h3>
                  <InputGroup className="ml-auto" style={{ maxWidth: "400px" }}>
                    <Input
                      placeholder="Search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <InputGroupAddon addonType="append">
                      <Button color="primary">Search</Button>
                    </InputGroupAddon>
                  </InputGroup>
                  <InputGroup className="ml-2" style={{ maxWidth: "400px" }}>
                    <Input
                      type="date"
                      placeholder="Start Date"
                      value={initialDate.start}
                      onChange={(e) =>
                        setInitialDate({
                          ...initialDate,
                          start: e.target.value,
                        })
                      }
                    />
                    <Input
                      type="date"
                      placeholder="End Date"
                      value={initialDate.end}
                      onChange={(e) =>
                        setInitialDate({ ...initialDate, end: e.target.value })
                      }
                    />
                    <InputGroupAddon addonType="prepend">
                      <Button
                        color="primary"
                        onClick={() => {
                          setFilterDate(initialDate);
                        }}
                      >
                        Date Range
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                  <UncontrolledDropdown className="ml-2">
                    <DropdownToggle caret color="primary">
                      Buy Method
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() => {
                          setBuyMethod("");
                        }}
                      >
                        All
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          setBuyMethod("USD");
                        }}
                      >
                        USD
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          setBuyMethod("ETH");
                        }}
                      >
                        ETH
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          setBuyMethod("WBTC");
                        }}
                      >
                        WBTC
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          setBuyMethod("BNB");
                        }}
                      >
                        BNB
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown className="ml-2">
                    <DropdownToggle caret color="primary">
                      Download Report
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() => {
                          if (filteredTx.length === 0) {
                            swalFire("error", "No data available");
                            return;
                          }

                          handleCSVDownload(
                            filteredTx,
                            desiredFields,
                            "Transactions.csv"
                          );
                        }}
                      >
                        CSV
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          if (filteredTx.length === 0) {
                            swalFire("error", "No data available");
                            return;
                          }

                          handleExcelDownload(
                            filteredTx,
                            desiredFields,
                            "Transactions.xlsx"
                          );
                        }}
                      >
                        Excel
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Sn</th>
                    <th scope="col">Username</th>
                    <th scope="col">Date & Time</th>
                    <th scope="col">Buy Method</th>
                    <th scope="col">Deposit Wallet</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Transaction Hash</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>{renderTableRows()}</tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem>
                      <PaginationLink
                        className="disabled"
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setPageNumber(
                            pageNumber > 1 ? pageNumber - 1 : pageNumber
                          );
                        }}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    {Array.from(
                      { length: buyData?.data?.data?.totalPages },
                      (_, index) => (
                        <PaginationItem
                          key={index}
                          className={pageNumber === index + 1 ? "active" : ""}
                        >
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => {
                              e.preventDefault();
                              setPageNumber(index + 1);
                            }}
                          >
                            {index + 1}
                          </PaginationLink>
                        </PaginationItem>
                      )
                    )}
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setPageNumber(
                            pageNumber < buyData?.data?.data?.totalPages
                              ? pageNumber + 1
                              : pageNumber
                          );
                        }}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default FullBuyTransactionTable;
