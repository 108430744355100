import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  CardHeader,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { fetchAdminProfile } from "services/api";
import { updateAdminProfile } from "services/api";
import { swalFire } from "services/help";
import { updateAdminDp } from "services/api";
import { updateAdminPassword } from "services/api";

const Profile = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const [password, setPassword] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [initialData, setInitialData] = useState();
  const [updatedData, setUpdateData] = useState();
  const [passwordErrors, setPasswordErrors] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    digit: false,
    specialChar: false,
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const dispatch = useDispatch();

  async function getAdminProfile() {
    try {
      let output = await fetchAdminProfile();
      console.log(output, "admin 98");
      setInitialData(output?.data?.admin);
      setUpdateData(output?.data?.admin);
    } catch (error) {
      console.error("Error fetching admin profile:", error);
    }
  }

  useEffect(() => {
    getAdminProfile();
  }, []);

  const validatePassword = (password) => {
    const errors = {
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      digit: /\d/.test(password),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    };

    setPasswordErrors(errors);
    return Object.values(errors).every((isValid) => isValid);
  };

  // const changePassword = async () => {
  //   const isValidPassword = validatePassword(password.newPassword);
  //   if (!isValidPassword) {
  //     return;
  //   }

  //   let output = await updateAdminPassword(password);
  //   if (output.admin) {
  //     swalFire("success", "Password updated successfully");
  //     dispatch({
  //       type: "LOGIN",
  //       payload: { admin: output?.admin },
  //     });
  //     localStorage.removeItem("accessToken");
  //     navigate("/auth/index");
  //   }

  //   setPassword({
  //     currentPassword: "",
  //     newPassword: "",
  //     confirmPassword: "",
  //   });
  //   console.log("output update admin profle : ", output);
  // };

  const updateProfile = async () => {
    if (updatedData === initialData) {
      swalFire("error", "Please update the data before change");
      return;
    }
    let output = await updateAdminProfile(updatedData);
    if (output.admin) {
      swalFire("success", "Profile updated successfully");
      dispatch({
        type: "LOGIN",
        payload: { admin: output?.admin },
      });
      await getAdminProfile();
    }
    console.log("output update admin profle : ", output);
  };

  const changePassword = async () => {
    const isValidPassword = validatePassword(password.newPassword);

    if (!isValidPassword) {
      // swalFire("error", "Please fix the following issues with your password.");
      return;
    }

    try {
      const output = await updateAdminPassword(password);

      if (output.admin) {
        swalFire("success", "Password updated successfully");
        dispatch({
          type: "LOGIN",
          payload: { admin: output?.admin },
        });
        localStorage.removeItem("accessToken");
        navigate("/auth/index");
      }
    } catch (error) {
      console.error("Error updating password:", error);
      swalFire("error", "An error occurred while updating the password.");
    }

    setPassword({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  };

  const handleChangePassword = (e) => {
    const { name, value } = e.target;
    console.log("name, value : ", name, value);
    setPassword((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    changePassword();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("name, value : ", name, value);
    setUpdateData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    try {
      let output = await updateAdminDp(file);
      console.log(output);
    } catch (e) {
      console.log(e);
    }

    const reader = new FileReader();

    reader.onload = () => {
      setSelectedImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const getDp = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/download-dp?keydoc=${updatedData?.kycDocument}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch image");
      }
      const dpUrl = await response.blob();
      return URL.createObjectURL(dpUrl);
    } catch (error) {
      console.error("Error fetching profile image:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchImage = async () => {
      const dpUrl = await getDp();
      setSelectedImage(dpUrl);
    };

    fetchImage();
  }, [updatedData]);

  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* className="justify-content-center" */}
        <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <Card className="card-profile shadow">
              <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                <Form onSubmit={handleSubmit}>
                  <h6 className="heading-small text-muted mb-4">
                    Change Password
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="currentPassword"
                          >
                            Current Password
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={password.currentPassword}
                            name="currentPassword"
                            id="input-username"
                            placeholder="Current Password"
                            type="password"
                            onChange={handleChangePassword}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="newPassword"
                          >
                            New Password
                          </label>
                          <Input
                            className="form-control-alternative"
                            type="password"
                            name="newPassword"
                            placeholder="New Password"
                            value={password.newPassword}
                            onChange={handleChangePassword}
                          />
                          {formSubmitted && !passwordErrors.length && (
                            <div className="text-danger mt-1 small">
                              Password must be at least 8 characters long.
                            </div>
                          )}
                          {formSubmitted && !passwordErrors.uppercase && (
                            <div className="text-danger mt-1 small">
                              Password must include at least one uppercase
                              letter.
                            </div>
                          )}
                          {formSubmitted && !passwordErrors.lowercase && (
                            <div className="text-danger mt-1 small">
                              Password must include at least one lowercase
                              letter.
                            </div>
                          )}
                          {formSubmitted && !passwordErrors.digit && (
                            <div className="text-danger mt-1 small">
                              Password must include at least one digit.
                            </div>
                          )}
                          {formSubmitted && !passwordErrors.specialChar && (
                            <div className="text-danger mt-1 small">
                              Password must include at least one special
                              character.
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="confirmPassword"
                          >
                            Confirm Password
                          </label>
                          <Input
                            className="form-control-alternative"
                            name="confirmPassword"
                            placeholder="Confirm Password"
                            value={password.confirmPassword}
                            onChange={handleChangePassword}
                            type="password"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <Button color="info" type="submit">
                    Change Password
                  </Button>
                </Form>
              </CardHeader>
            </Card>
          </Col>

          <Col className="order-xl-1" xl="8">
            {/* <Card className="card-profile shadow"> */}
            <Row className="justify-content-center">
              <Col className="order-lg-2" lg="3" style={{ zIndex: 1000 }}>
                <div className="card-profile-image">
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    className="form-control"
                    placeholder="Enter password"
                    style={{ display: "none" }} // Hide the file input
                  />

                  <a href="javascript:;" onClick={handleImageClick}>
                    <img
                      alt="profile image"
                      className="rounded-circle"
                      src={
                        selectedImage
                          ? selectedImage
                          : async () => {
                              await getDp();
                            }
                      }
                      style={{ width: "150px", height: "150px" }}
                    />
                  </a>
                </div>
              </Col>
            </Row>
            {/* </Card> */}

            <Card className="bg-secondary shadow">
              <CardBody
                style={{
                  marginTop: "80px",
                  marginBottom: "80px",
                }}
              >
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    User information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="username"
                          >
                            Username
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={updatedData?.username}
                            name="username"
                            placeholder="username"
                            type="text"
                            onChange={handleChange}
                            maxLength={30}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="email">
                            Email address
                          </label>
                          <Input
                            className="form-control-alternative"
                            name="email"
                            placeholder="jesse@example.com"
                            value={updatedData?.email}
                            type="email"
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="firstname"
                          >
                            First name
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={updatedData?.firstname}
                            name="firstname"
                            placeholder="First name"
                            type="text"
                            onChange={handleChange}
                            maxLength={30}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="lastname"
                          >
                            Last name
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={updatedData?.lastname}
                            name="lastname"
                            placeholder="Last name"
                            type="text"
                            onChange={handleChange}
                            maxLength={30}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                    Contact information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="address"
                          >
                            Address
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={updatedData?.address}
                            name="address"
                            placeholder="Home Address"
                            type="text"
                            onChange={handleChange}
                            maxLength={100}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="city">
                            City
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={updatedData?.city}
                            name="city"
                            placeholder="City"
                            type="text"
                            onChange={handleChange}
                            maxLength={50}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="country"
                          >
                            Country
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={updatedData?.country}
                            name="country"
                            placeholder="Country"
                            type="text"
                            onChange={handleChange}
                            maxLength={30}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="pcode">
                            Postal code
                          </label>
                          <Input
                            className="form-control-alternative"
                            name="postalCode"
                            value={updatedData?.postalCode}
                            placeholder="Postal code"
                            type="number"
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <div
                    className="justify-content-center"
                    style={{
                      display: "flex",
                    }}
                  >
                    <Button
                      color="info"
                      href="#pablo"
                      onClick={async (e) => {
                        e.preventDefault();
                        await updateProfile();
                      }}
                    >
                      Edit profile
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
