import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
} from "reactstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import { swalFire } from "services/help";
import { cleanHTMLTag } from "services/help";
import { ReplyToInquiry } from "services/api";
import { Loader } from "variables/Loader";

export const InquiryModel = ({ selectedInquiry, isOpen, toggle }) => {
  const [isContentValid, setIsContentValid] = useState(true);
  const [reply, setReply] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    if (reply.trim() === "") {
      setIsContentValid(false);
      return;
    }

    try {
      let data = { _id: selectedInquiry._id, reply: reply };
      console.log("data : ", data);
      const output = await ReplyToInquiry(data);

      console.log("output of ReplyToInquiry: ", output);
      if (output?.enquiry) {
        swalFire("success", output?.message);
      } else {
        swalFire("error", "Unable to sent reply");
      }
    } catch (e) {
      console.log("Error : ", e);
    }
    toggle(); // Close the modal after submission
    setLoading(false);
    setReply("");
  };

  const handleEditorChange = (content) => {
    let cleanedText = cleanHTMLTag(content);

    if (cleanedText === "") {
      return;
    }
    console.log("Content in Quill editor: ", cleanedText);
    setReply(content);
    setIsContentValid(true); // Reset validation state on change
  };

  console.log("reply : ", reply, reply === "<p><br></p>");

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Modal isOpen={isOpen} toggle={toggle}>
          <ModalHeader toggle={toggle}>Reply Inquiry</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                type="text"
                name="name"
                id="name"
                value={selectedInquiry?.name}
                disabled
              />
            </FormGroup>
            <FormGroup>
              <Label for="slug">Email</Label>
              <Input
                type="email"
                name="email"
                id="email"
                value={selectedInquiry?.email}
                disabled
              />
            </FormGroup>

            <FormGroup>
              <Label for="slug">Subject</Label>
              <Input
                type="text"
                name="subject"
                id="subject"
                value={selectedInquiry?.subject}
                disabled
              />
            </FormGroup>

            <FormGroup>
              <Label for="slug">Message</Label>
              <Input
                type="textarea"
                name="message"
                id="message"
                value={selectedInquiry?.message}
                rows="5"
                cols="50"
                disabled
              />
            </FormGroup>

            <FormGroup>
              <Label for="reply">Reply</Label>
              <ReactQuill
                value={reply}
                onChange={handleEditorChange}
                style={{ height: "200px" }}
              />
            </FormGroup>
          </ModalBody>
          <div style={{ margin: "auto" }}>
            {(reply?.trim() === "" || reply === "<p><br></p>") && (
              <small className="text-danger">Content required</small>
            )}
          </div>
          <ModalFooter>
            <Button
              color="primary"
              onClick={handleSubmit}
              disabled={reply.trim() === "" || reply === "<p><br></p>"}
            >
              Reply
            </Button>
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};
