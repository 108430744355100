import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import Header from "components/Headers/Header";
import { ethers } from "ethers";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
  Table,
} from "reactstrap";
import { getOwner } from "services/contractHelper";
import { deleteStakePlan } from "services/contractHelper";
import { updateStakePlan } from "services/contractHelper";
import { addNewStakePlan } from "services/contractHelper";
import { getStakePlans } from "services/contractHelper";
import { swalFire } from "services/help";
import { monthsToSeconds } from "services/help";
import { secondToMonth } from "services/help";
import Swal from "sweetalert2";
import { Loader } from "variables/Loader";

export const StakeFunctions = () => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const [toggle, setToggle] = useState(false);
  const [duration, setDuration] = useState("");
  const [updateDuration, setUpdateDuration] = useState("");
  const [apr, setApr] = useState("");
  const [selectedStakeId, setSelectedStakeId] = useState("");
  const [updatedApr, setUpdatedApr] = useState("");
  const [stakePlans, setStakePlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [owner, setOwner] = useState("");

  const durationOptions = [
    { value: 300, label: "5 Minutes" },
    { value: monthsToSeconds(3), label: "3 months" },
    { value: monthsToSeconds(6), label: "6 months" },
    { value: monthsToSeconds(9), label: "9 months" },
    { value: monthsToSeconds(12), label: "12 months" },
    { value: monthsToSeconds(15), label: "15 months" },
    { value: monthsToSeconds(18), label: "18 months" },
    { value: monthsToSeconds(21), label: "21 months" },
    { value: monthsToSeconds(24), label: "24 months" },
  ];

  const handleAddStakePlan = async () => {
    if (!isConnected) {
      swalFire("error", "Please connect to the metamask wallet");
      return;
    }

    if (!window.ethereum) {
      window.open(
        `https://metamask.app.link/dapp/${window.location.hostname}/`
      );
    }

    if (address !== owner) {
      swalFire("error", "You are not the owner of the contract");
      return;
    }

    setLoading(true);

    if (!duration) {
      swalFire("error", "Duration is not set");
      setLoading(false);
      return;
    }

    if (!apr) {
      swalFire("error", "APR is not set");
      setLoading(false);
      return;
    }

    try {
      await window.ethereum.request({ method: "eth_requestAccounts" });
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      const transaction = await addNewStakePlan(duration, apr * 100, signer);
      console.log("Transaction successful:", transaction);

      if (transaction?.hash) {
        swalFire("success", "New Stake plan added successfully");
      } else {
        swalFire("error", transaction);
      }
    } catch (error) {
      console.error("Transaction failed:", error);
      swalFire("error", "Purchase failed.");
    }

    setDuration("");
    setApr("");
    setLoading(false);
  };

  const handleUpdateStakePlan = async (e) => {
    if (!isConnected) {
      swalFire("error", "Please connect to the metamask wallet");
      return;
    }

    if (!window.ethereum) {
      window.open(
        `https://metamask.app.link/dapp/${window.location.hostname}/`
      );
    }

    e.preventDefault();
    if (address !== owner) {
      swalFire("error", "You are not the owner of the contract");
      return;
    }

    console.log(
      "Update Stake Plan:",
      selectedStakeId,
      updateDuration,
      updatedApr * 100
    );
    if (!selectedStakeId || !updateDuration || !updatedApr) {
      swalFire("error", "All fields are required");
      return;
    }
    try {
      setLoading(true);
      await window.ethereum.request({ method: "eth_requestAccounts" });
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      const transaction = await updateStakePlan(
        selectedStakeId,
        updateDuration,
        updatedApr * 100,
        signer
      );
      if (transaction?.hash) {
        swalFire("success", "Stake plan updated successfully");
      } else {
        swalFire("error", transaction);
      }
    } catch (e) {
      console.log(e);
    }
    setSelectedStakeId("");
    setUpdatedApr("");
    setLoading(false);
  };

  const handleDeleteStakePlan = async (planId) => {
    if (!window.ethereum) {
      window.open(
        `https://metamask.app.link/dapp/${window.location.hostname}/`
      );
    }

    if (address !== owner) {
      swalFire("error", "You are not the owner of the contract");
      return;
    }
    console.log("toDelete : " + planId);

    if (!planId) {
      swalFire("error", "Plan Id not found");
      return;
    }
    try {
      if (!window.ethereum) {
        window.open(
          `https://metamask.app.link/dapp/${window.location.hostname}/`
        );
      }

      setLoading(true);
      await window.ethereum.request({ method: "eth_requestAccounts" });
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      const transaction = await deleteStakePlan(planId, signer);
      if (transaction?.hash) {
        swalFire("success", "Stake plan deleted successfully");
      } else {
        swalFire("error", transaction);
      }
    } catch (e) {
      console.log(e);
    }

    setLoading(false);
  };

  const fetchData2 = async () => {
    try {
      const sPlan = await getStakePlans();
      const planIds = sPlan[0];
      const durations = sPlan[1];
      const aprs = sPlan[2];

      const plans = planIds.map((id, index) => ({
        planId: planIds[index].toString(), // Convert BigNumber to string
        duration: durations[index].toString(), // Convert BigNumber to string
        apr: aprs[index].toString(), // Convert BigNumber to string
      }));

      setStakePlans(plans);

      const owner = await getOwner();
      setOwner(owner);
    } catch (error) {
      console.log("Error fetching staking plans:", error);
    }
  };

  const handleDeleteClick = async (planId) => {
    if (!isConnected) {
      swalFire("error", "Please connect to the metamask wallet");
      return;
    }

    console.log("Plan Id: " + planId);
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this transaction!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
    if (result.isConfirmed) {
      await handleDeleteStakePlan(planId);
    }
  };

  useEffect(() => {
    try {
      fetchData2();
    } catch (err) {
      console.log(err);
    }
  }, [isConnected, address, loading]);

  console.log("stakePlans : ", stakePlans);
  console.log("owner : ", owner);

  return (
    <>
      {loading ? <Loader /> : null}
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col lg="8" md="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row">
                  <div className="col">
                    <h3 className="mb-0">Stake Plans</h3>
                  </div>
                  <div className="col text-right">
                    {/* Wallet connect on webstie    */}
                    {/* <w3m-button /> */}
                  </div>
                </div>
              </CardHeader>
              <div className="table-responsive">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Plan Id</th>
                      <th scope="col">Duration</th>
                      <th scope="col">APR</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stakePlans && stakePlans.length > 0 ? (
                      stakePlans.map((item, index) => (
                        <tr key={index}>
                          <td>{item.planId}</td>
                          <td>{secondToMonth(item.duration)} months</td>
                          <td>{(item.apr / 100).toFixed(2)}%</td>
                          <td>
                            <Button
                              color="danger"
                              size="sm"
                              className="mr-2"
                              onClick={async () =>
                                await handleDeleteClick(item?.planId)
                              }
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">
                          No data available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Card>
          </Col>
          <Col lg="4" md="12">
            <Card className="shadow">
              <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                <Form>
                  <h6 className="heading text-muted mb-4">Add Stake Plan</h6>
                  <div className="pl-lg-4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="duration">
                        Duration (months)
                      </label>
                      <Input
                        type="select"
                        name="duration"
                        id="duration"
                        className="form-control-alternative"
                        value={duration}
                        onChange={(e) => setDuration(e.target.value)}
                        required
                      >
                        <option value="">Select duration</option>
                        {durationOptions.map((opt) => (
                          <option key={opt.value} value={opt.value}>
                            {opt.label}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <label className="form-control-label" htmlFor="apr">
                        APR (%)
                      </label>
                      <Input
                        className="form-control-alternative"
                        name="apr"
                        id="apr"
                        placeholder="Enter APR percentage"
                        type="number"
                        min="0"
                        step="0.01"
                        value={apr}
                        onChange={(e) => setApr(e.target.value)}
                        required
                      />
                    </FormGroup>
                  </div>
                  <Button color="primary" onClick={handleAddStakePlan}>
                    Add Stake Plan
                  </Button>
                </Form>
              </CardHeader>
            </Card>
            {/* Update Stake Plan Card */}
            <Card className="shadow mt-4">
              <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                <Form onSubmit={handleUpdateStakePlan}>
                  <h6 className="heading text-muted mb-4">Update Stake Plan</h6>
                  <div className="pl-lg-4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="selectStakeId"
                      >
                        Select Stake Plan ID
                      </label>
                      <Input
                        type="select"
                        name="selectStakeId"
                        id="selectStakeId"
                        className="form-control-alternative"
                        value={selectedStakeId}
                        onChange={(e) => setSelectedStakeId(e.target.value)}
                        required
                      >
                        <option value="">Select stake plan ID</option>
                        {stakePlans.map((item) => (
                          <option key={item.planId} value={item.planId}>
                            {item.planId}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>

                    <FormGroup>
                      <label className="form-control-label" htmlFor="duration">
                        Duration (months)
                      </label>
                      <Input
                        type="select"
                        name="duration"
                        id="duration"
                        className="form-control-alternative"
                        value={updateDuration}
                        onChange={(e) => setUpdateDuration(e.target.value)}
                        required
                      >
                        <option value="">Select duration</option>
                        {durationOptions.map((opt) => (
                          <option key={opt.value} value={opt.value}>
                            {opt.label}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>

                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="updatedApr"
                      >
                        Update APR (%)
                      </label>
                      <Input
                        className="form-control-alternative"
                        name="updatedApr"
                        id="updatedApr"
                        placeholder="Enter updated APR percentage"
                        type="number"
                        min="0"
                        step="0.01"
                        value={updatedApr}
                        onChange={(e) => setUpdatedApr(e.target.value)}
                        required
                      />
                    </FormGroup>
                  </div>
                  <Button color="primary" type="submit">
                    Update Stake Plan
                  </Button>
                </Form>
              </CardHeader>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
