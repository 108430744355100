import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllStakeTx } from "services/api";

import { Button, Card, CardHeader, Table, Row, Col, Badge } from "reactstrap";
import { formatDate } from "services/help";

const RecentStake = () => {
  const navigate = useNavigate();
  const [stakeData, setStakeData] = useState([]);

  const trimAddress = (address) => {
    if (!address || typeof address !== "string") {
      return "";
    }
    if (address.length <= 15) {
      return address;
    } else {
      return address.slice(0, 10) + "..." + address.slice(-5);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let data = {
          page: 1,
          limit: 5,
        };
        const output = await getAllStakeTx(data);

        setStakeData(output?.data?.docs);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle errors here, such as displaying an error message or setting a state variable
      }
    };

    fetchData(); // Call the fetchData function
  }, []);

  console.log("setStakeData : ", stakeData);

  return (
    <Row className="mt-5">
      <Col className="mb-5 mb-xl-0" xl="12">
        <Card className="shadow">
          <CardHeader className="border-0">
            <Row className="align-items-center">
              <div className="col">
                <h3 className="mb-0">Recent Staking</h3>
              </div>
              <div className="col text-right">
                <Button
                  color="primary"
                  href="#pablo"
                  onClick={() => {
                    navigate("/admin/stake");
                  }}
                  size="sm"
                >
                  See all staking
                </Button>
              </div>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">Username</th>
                <th scope="col">Date & Time</th>
                <th scope="col">Durations</th>
                <th scope="col">User Wallet</th>
                <th scope="col">Amount</th>
                <th scope="col">Transaction Hash</th>
                <th scope="col">Staus</th>
              </tr>
            </thead>
            <tbody>
              {stakeData?.map((item, index) => (
                <tr key={index}>
                  <th scope="row">{item.username}</th>
                  <td>{formatDate(item.createdAt)}</td>
                  <td>{item.durations}</td>
                  <td>{trimAddress(item.wallet)}</td>
                  <td>{item.token} CUCC</td>
                  <td>{trimAddress(item.transactionHash)}</td>
                  <td>
                    {item.status}
                    {/* <Badge color="" className="badge-dot mr-4"> */}
                    {/* <i
                        className={`bg-${
                          item.status === "completed" ? "success" : "warning"
                        }`}
                      />
                      {item.status === "completed" ? "completed" : "failed"} */}
                    {/* </Badge> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default RecentStake;
