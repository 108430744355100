import React, { useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import { NavLink } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Nav, NavItem, Row } from "reactstrap";
import classnames from "classnames";
import { getSaleChartData } from "services/api";
import { Loader } from "variables/Loader";

const SalesChart = ({ chartExample1Data }) => {
  const [loading, setLoading] = useState(false);
  const color = "#212529";
  const [chartData, setChartData] = useState({
    labels: ["Jul"],
    datasets: [
      {
        label: "Sales",
        data: [25],
      },
    ],
  });

  let chartExample1 = {
    options: {
      scales: {
        yAxes: [
          {
            gridLines: {
              color: color,
              zeroLineColor: color,
            },
            ticks: {
              callback: function (value) {
                if (!(value % 10)) {
                  return value + " CUCC";
                }
              },
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: function (item, data) {
            var label = data.datasets[item.datasetIndex].label || "";
            var yLabel = item.yLabel;
            var content = "";

            if (data.datasets.length > 1) {
              content += label;
            }

            content += yLabel + " CUCC";
            return content;
          },
        },
      },
    },
    data1: (canvas) => {
      return chartData;
    },
  };

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const response = await getSaleChartData();
        const data = response.data;

        const labels = data.map((item) => item.month);
        const salesData = data.map((item) => item.totalSales);

        setChartData({
          labels: labels,
          datasets: [
            {
              label: "Sales",
              data: salesData, // assuming data is an array of numbers
            },
          ],
        });
      } catch (error) {
        console.error("Error fetching sale chart data:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      {loading ? <Loader /> : null}
      <Col className="mb-5 mb-xl-0" xl="8">
        <Card className="bg-gradient-default shadow">
          <CardHeader className="bg-transparent">
            <Row className="align-items-center">
              <div className="col">
                <h6 className="text-uppercase text-light ls-1 mb-1">
                  Overview
                </h6>
                <h2 className="text-white mb-0">Copper Coin Sales </h2>
              </div>
              <div className="col">
                <Nav className="justify-content-end" pills>
                  <NavItem>
                    <NavLink
                      className={classnames("py-2 px-3", {
                        active: true,
                      })}
                      href="#pablo"
                    >
                      <span className="d-none d-md-block">Month</span>
                      <span className="d-md-none">M</span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </Row>
          </CardHeader>
          <CardBody>
            {/* Chart */}
            <div className="chart">
              <Line
                data={chartExample1[chartExample1Data]}
                options={chartExample1.options}
                getDatasetAtEvent={(e) => console.log(e)}
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default SalesChart;
