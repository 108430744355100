import { configureStore } from "@reduxjs/toolkit";

import admin from "../slice/auth";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const rootReducer = combineReducers({
  admin,
});
const persistConfig = {
  key: "root",
  version: 1,
  storage: storage,
  whitelist: ["admin"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});
