import { Button, Card, CardBody, FormGroup, Form } from "reactstrap";
import { Input, Container, Row, Col, CardTitle } from "reactstrap";
import { useEffect, useState } from "react";
import { swalFire } from "services/help";
import Header from "components/Headers/Header";
import { formatNumber } from "services/help";
import { ethers } from "ethers";
import { Loader } from "variables/Loader";
import { fetchAdminProfile } from "services/api";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { EthereumContractAddress } from "services/contractHelper";
import { CopperAddress1 } from "services/contractHelper";
import { USDTAddress1 } from "services/contractHelper";
import { WBTCAddress1 } from "services/contractHelper";
import { withdrawICOSend } from "services/contractHelper";
import { getSaleStakeData } from "services/contractHelper";
import { withdrawStakeSend } from "services/contractHelper";

const SaleStakeManage = () => {
  const { isConnected } = useWeb3ModalAccount();
  const [IcoContract, setIcoContract] = useState("");
  const [icoAmount, setIcoAmount] = useState("");
  const [stakeCucc, setStakeCucc] = useState("");
  const [read, setRead] = useState({
    CUCC_ON_ICO: 0,
    ETH_ON_ICO: 0,
    USDT_ON_ICO: 0,
    WBTC_ON_ICO: 0,
    CUCC_ON_STAKE: 0,
  });

  console.log("read : ", read);

  const [loading, setLoading] = useState(false);
  const [admin, setAdmin] = useState(null);

  const durationOptions = [
    { value: CopperAddress1, label: "CUCC" },
    { value: EthereumContractAddress, label: "ETH" },
    { value: USDTAddress1, label: "USDT" },
    { value: WBTCAddress1, label: "WBTC" },
  ];

  async function getAdminProfile() {
    try {
      let output = await fetchAdminProfile();
      setAdmin(output?.data?.admin);
    } catch (error) {
      console.error("Error fetching admin profile:", error);
    }
  }

  useEffect(() => {
    getAdminProfile();
  }, []);

  async function getData() {
    setLoading(true);
    try {
      let output = await getSaleStakeData();
      setRead(output);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching admin profile:", error);
      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  const handleWithdrawICO = async () => {
    if (!isConnected) {
      swalFire("error", "Please connect to the metamask wallet");
      return;
    }
    setLoading(true);
    try {
      if (!window.ethereum) {
        window.open(
          `https://metamask.app.link/dapp/${window.location.hostname}/`
        );
      }

      await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      console.log(
        "sale ,signer, IcoContract, icoAmount : ",
        "sale",

        IcoContract,
        icoAmount
      );

      const transaction = await withdrawICOSend(signer, IcoContract, icoAmount);
      if (transaction.status === "success") {
        await getData();
        swalFire("success", "Successfully Withdraw Tokens");
        setIcoAmount("");
        setIcoContract("");
      } else {
        swalFire("error", transaction?.msg);
      }
    } catch (error) {
      console.error("Transaction failed:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleWithdrawStake = async () => {
    if (!isConnected) {
      swalFire("error", "Please connect to the metamask wallet");
      return;
    }
    setLoading(true);
    try {
      if (!window.ethereum) {
        window.open(
          `https://metamask.app.link/dapp/${window.location.hostname}/`
        );
      }

      await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      const transaction = await withdrawStakeSend(signer, stakeCucc);
      if (transaction.status === "success") {
        await getData();
        swalFire("success", "Successfully Withdraw Tokens");
        setStakeCucc("");
      } else {
        swalFire("error", transaction?.msg);
      }
    } catch (error) {
      console.error("Transaction failed:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          {/* <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <Card className="card-profile shadow">
              <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    Set cron for rebalanced automatically
                  </h6>

                  <Row style={{ display: "flex", justifyContent: "center" }}>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="newPassword"
                        >
                          Time in hours
                        </label>
                        <Input
                          className="form-control-alternative"
                          type="text"
                          name="minutes"
                          placeholder="In hours"
                          value={cronTime}
                          onChange={(e) => {
                            const { value } = e.target;
                            if (/^\d+$/.test(value) || value === "") {
                              setCronTime(value);
                            }
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Button
                    color="info"
                    href="#pablo"
                    onClick={async () => {
                      if (!cronTime > 0) {
                        swalFire("error", "Invalid cron time ");
                        return;
                      }
                      const result = await resetCronTimeApi({
                        time: cronTime,
                      });
                      setCronTime("");
                      if (result?.admin) {
                        swalFire("success", result?.message);
                      } else {
                        console.log("Error in resetCronTimeApi : ", result);
                        swalFire(result?.error);
                      }
                      console.log("resetCronTimeApi called result : ", result);
                    }}
                  >
                    Reset Crom Time
                  </Button>
                </Form>
              </CardHeader>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <h6 className="heading-small text-muted mb-4">
                  {admin?.cronTime > 0
                    ? `A Cron will automatically run in each ${admin?.cronTime} hours.`
                    : "Cron is disabled"}
                </h6>
              </div>
            </Card>
          </Col> */}

          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
              <CardBody>
                <div
                  style={{
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                >
                  <Row
                    style={{
                      margin: 20,
                    }}
                  >
                    {/* Copper Total Supply  */}
                    <Col
                      lg="6"
                      xl="3"
                      style={{
                        marginBottom: 20,
                      }}
                    >
                      <Card className="card-stats mb-4 mb-xl-0">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                CUCC on ICO
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0">
                                {formatNumber(read?.CUCC_ON_ICO, 2) ?? 0} CUCC
                              </span>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>

                    {/* Rebalance Count  */}
                    <Col
                      lg="6"
                      xl="3"
                      style={{
                        marginBottom: 20,
                      }}
                    >
                      <Card className="card-stats mb-4 mb-xl-0">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                Ethereum on ICO
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0">
                                {read?.ETH_ON_ICO ?? 0} {"ETH"}
                              </span>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>

                    {/* Live Copper Price  */}
                    <Col
                      lg="6"
                      xl="3"
                      style={{
                        marginBottom: 20,
                      }}
                    >
                      <Card className="card-stats mb-4 mb-xl-0">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                USDT on ICO
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0">
                                $ {formatNumber(read?.USDT_ON_ICO, 2) ?? 0}{" "}
                                {"USDT"}
                              </span>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>

                    {/* Live ETH Price  */}
                    <Col
                      lg="6"
                      xl="3"
                      style={{
                        marginBottom: 20,
                      }}
                    >
                      <Card className="card-stats mb-4 mb-xl-0">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                WBTC on ICO
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0">
                                $ {formatNumber(read?.WBTC_ON_ICO, 5) ?? 0}
                                {"BTC"}
                              </span>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>

                    {/* CUCC For Reserve ETH  */}
                    <Col
                      lg="6"
                      xl="3"
                      style={{
                        marginBottom: 20,
                      }}
                    >
                      <Card className="card-stats mb-4 mb-xl-0">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                CUCC on Staking
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0">
                                {formatNumber(read?.CUCC_ON_STAKE, 2) ?? 0} CUCC
                              </span>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>

            {/* New Section for Minting Tokens */}
            <Card className="bg-secondary shadow mt-4">
              <CardBody>
                <Row>
                  {/* Deposit ETH For Reserve */}
                  <Col lg="6">
                    <Form>
                      <h6 className="heading text-muted mb-4 pl-lg-4">
                        Withdraw Crypto Form Sale
                      </h6>
                      <div className="pl-lg-4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="IcoContract"
                          >
                            Select Contract
                          </label>
                          <Input
                            type="select"
                            name="IcoContract"
                            id="IcoContract"
                            className="form-control-alternative"
                            value={IcoContract}
                            onChange={(e) => setIcoContract(e.target.value)}
                            required
                          >
                            <option value="">Select contract</option>
                            {durationOptions.map((opt) => (
                              <option key={opt.value} value={opt.value}>
                                {opt.label}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                        <FormGroup>
                          <label className="form-control-label" htmlFor="apr">
                            Amount
                          </label>
                          <Input
                            className="form-control-alternative"
                            name="apr"
                            id="apr"
                            placeholder="Enter APR percentage"
                            type="number"
                            min="0"
                            step="0.01"
                            value={icoAmount}
                            onChange={(e) => setIcoAmount(e.target.value)}
                            required
                          />
                        </FormGroup>
                        <Button color="info" onClick={handleWithdrawICO}>
                          Withdraw Amount
                        </Button>
                      </div>
                    </Form>
                  </Col>

                  {/* Withdraw ETH From Reserve */}
                  <Col lg="6">
                    <h6 className="heading text-muted mb-4 pl-lg-4">
                      Withdraw CUCC From Staking
                    </h6>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="mintAmount2"
                      >
                        Amount
                      </label>
                      <Input
                        className="form-control-alternative"
                        type="number"
                        name="withdrawAmount"
                        placeholder="Enter withdraw amount"
                        value={stakeCucc}
                        onChange={(e) => {
                          if (e.target.value >= 0) {
                            setStakeCucc(e.target.value);
                          }
                        }}
                      />
                      <Button
                        color="info"
                        className="mt-3"
                        onClick={handleWithdrawStake}
                      >
                        Withdraw Amount
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row></Row>
      </Container>
    </>
  );
};

export default SaleStakeManage;
