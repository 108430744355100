import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { getStakeChartData } from "services/api";

const StakeChart = () => {
  const [chartData, setChartData] = useState({
    labels: ["Jul"],
    datasets: [
      {
        label: "Sales",
        data: [25],
        maxBarThickness: 10,
      },
    ],
  });

  let chartExample2 = {
    options: {
      scales: {
        yAxes: [
          {
            ticks: {
              callback: function (value) {
                if (!(value % 10)) {
                  return value;
                }
              },
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: function (item, data) {
            var label = data.datasets[item.datasetIndex].label || "";
            var yLabel = item.yLabel;
            var content = "";
            if (data.datasets.length > 1) {
              content += label;
            }
            content += yLabel;
            return content;
          },
        },
      },
    },
    data: chartData,
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getStakeChartData();
        const data = response.data;

        const labels = data.map((item) => item.month);
        const salesData = data.map((item) => item.totalSales);

        setChartData({
          labels: labels,
          datasets: [
            {
              label: "Sales",
              data: salesData, // assuming data is an array of numbers
              maxBarThickness: 10,
            },
          ],
        });
      } catch (e) {
        console.log("Error in fetching getStakeChartData : ", e);
      }
    }
    fetchData();
  }, []);

  console.log("chartData : ", chartData);

  return (
    <>
      <Col xl="4">
        <Card className="shadow">
          <CardHeader className="bg-transparent">
            <Row className="align-items-center">
              <div className="col">
                <h6 className="text-uppercase text-muted ls-1 mb-1">
                  Performance
                </h6>
                <h2 className="mb-0">Total orders</h2>
              </div>
            </Row>
          </CardHeader>
          <CardBody>
            <div className="chart">
              <Bar data={chartExample2.data} options={chartExample2.options} />
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default StakeChart;
