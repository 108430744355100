import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
} from "reactstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import { swalFire } from "services/help";
import { cleanHTMLTag } from "services/help";
import { updateContent } from "services/api";
import { trimContent } from "services/help";

export const ContentModal = ({ data, isOpen, toggle }) => {
  const [templateContent, setTemplateContent] = useState(data?.value || "");
  const [isContentValid, setIsContentValid] = useState(true);
  const [newData, setNewData] = useState(data?.value || "");
  const [error, setError] = useState("");

  const maxCharacters = 450; // Define maximum characters

  useEffect(() => {
    setTemplateContent(data?.value || "");
    setNewData(data?.value || "");
  }, [data]);

  const handleSubmit = async () => {
    if (templateContent.trim() === "") {
      setError("Content required");
      return;
    }

    if (templateContent.length > maxCharacters) {
      setError("Email content exceed 100 char");
      return;
    }

    if (newData === cleanHTMLTag(templateContent)) {
      swalFire("error", "Data not modified");
      return;
    }

    try {
      const output = await updateContent({
        _id: data?._id,
        value: newData,
      });

      if (output?.contents) {
        swalFire("success", "Content updated successfully");
      } else {
        swalFire("error", "Unable to update content");
      }
    } catch (e) {
      console.log("Error : ", e);
    }
    toggle(); // Close the modal after submission
  };

  const handleEditorChange = (content) => {
    let cleanedText = cleanHTMLTag(content);

    if (cleanedText === "") {
      setError("Content required");
    } else if (cleanedText.length > maxCharacters) {
      setError(`Email content exceed ${maxCharacters} char`);
    } else {
      setError("");
    }

    setTemplateContent(content);
    setNewData(content);
    setIsContentValid(true); // Reset validation state on change
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Edit Content</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="title">Title</Label>
          <Input
            type="text"
            name="title"
            id="title"
            value={trimContent(data?.key, 30)}
            disabled
          />
        </FormGroup>

        <FormGroup>
          <Label for="templateContent">Content</Label>
          <ReactQuill
            value={trimContent(templateContent, 500)}
            onChange={handleEditorChange}
            style={{ height: "200px" }}
          />
        </FormGroup>
      </ModalBody>
      <div style={{ margin: "auto" }}>
        {error && <small className="text-danger">{error}</small>}
      </div>

      <ModalFooter>
        <Button
          color="primary"
          onClick={handleSubmit}
          disabled={
            templateContent.trim() === "" ||
            templateContent === "<p><br></p>" ||
            error !== ""
          }
        >
          Save Changes
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

// import React, { useState, useEffect } from "react";
// import {
//   Button,
//   Input,
//   Modal,
//   ModalHeader,
//   ModalBody,
//   ModalFooter,
//   FormGroup,
//   Label,
// } from "reactstrap";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css"; // Import Quill styles
// import { swalFire } from "services/help";
// import { cleanHTMLTag } from "services/help";
// import { updateContent } from "services/api";
// import { trimContent } from "services/help";

// export const ContentModal = ({ data, isOpen, toggle }) => {
//   const [templateContent, setTemplateContent] = useState(data?.value || "");
//   const [isContentValid, setIsContentValid] = useState(true);
//   const [newData, setNewData] = useState(data?.value || "");

//   useEffect(() => {
//     setTemplateContent(data?.value || "");
//     setNewData(data?.value || "");
//   }, [data]);

//   const handleSubmit = async () => {
//     if (templateContent.trim() === "") {
//       setIsContentValid(false);
//       return;
//     }

//     console.log("newData === templateContent : ", newData, templateContent);

//     if (newData === cleanHTMLTag(templateContent)) {
//       swalFire("error", "Data not modified");
//       return;
//     }

//     try {
//       const output = await updateContent({
//         _id: data?._id,
//         value: newData,
//       });

//       console.log("output : ", output);
//       if (output?.contents) {
//         swalFire("success", "Content updated successfully");
//       } else {
//         swalFire("error", "Unable to update content");
//       }
//     } catch (e) {
//       console.log("Error : ", e);
//     }
//     toggle(); // Close the modal after submission
//   };

//   const handleEditorChange = (content) => {
//     let cleanedText = cleanHTMLTag(content);

//     if (cleanedText === "") {
//       return;
//     }
//     console.log("Content in Quill editor: ", cleanedText);
//     setTemplateContent(content);
//     setNewData(content);
//     setIsContentValid(true); // Reset validation state on change
//   };

//   return (
//     <Modal isOpen={isOpen} toggle={toggle}>
//       <ModalHeader toggle={toggle}>Edit Content</ModalHeader>
//       <ModalBody>
//         <FormGroup>
//           <Label for="title">Title</Label>
//           <Input
//             type="text"
//             name="title"
//             id="title"
//             value={trimContent(data?.key, 30)}
//             disabled
//           />
//         </FormGroup>

//         <FormGroup>
//           <Label for="templateContent">Content</Label>
//           <ReactQuill
//             value={trimContent(templateContent, 500)}
//             onChange={handleEditorChange}
//             style={{ height: "200px" }}
//           />
//         </FormGroup>
//       </ModalBody>
//       <div style={{ margin: "auto" }}>
//         {(templateContent.trim() === "" ||
//           templateContent === "<p><br></p>") && (
//           <small className="text-danger">Content required</small>
//         )}
//       </div>
//       <ModalFooter>
//         <Button
//           color="primary"
//           onClick={handleSubmit}
//           disabled={
//             templateContent.trim() === "" || templateContent === "<p><br></p>"
//           }
//         >
//           Save Changes
//         </Button>
//         <Button color="secondary" onClick={toggle}>
//           Cancel
//         </Button>
//       </ModalFooter>
//     </Modal>
//   );
// };
