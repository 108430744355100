import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
} from "reactstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import { updateEmail } from "services/api";
import { swalFire } from "services/help";
import { cleanHTMLTag } from "services/help";

export const EmailModal = ({ email, isOpen, toggle }) => {
  const [templateContent, setTemplateContent] = useState(email?.content || "");
  const [isContentValid, setIsContentValid] = useState(true);
  const [isContentLengthExceeded, setIsContentLengthExceeded] = useState(false);

  const MAX_CHARACTER = 2000;

  useEffect(() => {
    setTemplateContent(email?.content || "");
  }, [email]);

  const handleSubmit = async () => {
    if (templateContent.trim() === "") {
      setIsContentValid(false);
      setIsContentLengthExceeded(false);
      return;
    }

    if (templateContent.length > MAX_CHARACTER) {
      setIsContentLengthExceeded(true);
      setIsContentValid(true);
      return;
    }

    try {
      let data = { ...email, content: templateContent };
      const output = await updateEmail(data);

      if (output?.email) {
        swalFire("success", "Email updated successfully");
      } else {
        swalFire("error", "Unable to update Email");
      }
    } catch (e) {
      console.log("Error : ", e);
    }
    toggle(); // Close the modal after submission
  };

  const handleEditorChange = (content) => {
    let cleanedText = cleanHTMLTag(content);

    if (cleanedText === "") {
      return;
    }

    setTemplateContent(content);

    if (content.length > MAX_CHARACTER) {
      setIsContentLengthExceeded(true);
    } else {
      setIsContentLengthExceeded(false);
    }

    setIsContentValid(true); // Reset validation state on change
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} style={{ maxWidth: "800px" }}>
      <ModalHeader toggle={toggle}>Edit Email</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="title">Title</Label>
          <Input
            type="text"
            name="title"
            id="title"
            value={email?.title}
            disabled
          />
        </FormGroup>
        <FormGroup>
          <Label for="subject">Subject</Label>
          <Input
            type="text"
            name="subject"
            id="subject"
            value={email?.subject}
            disabled
          />
        </FormGroup>
        <FormGroup>
          <Label for="templateContent">Content</Label>
          <ReactQuill
            value={templateContent}
            onChange={handleEditorChange}
            style={{ height: "400px" }}
          />
        </FormGroup>
        <p>.</p>

        <p>
          {templateContent.trim() === "" && (
            <small className="text-danger">Email content required</small>
          )}
          {isContentLengthExceeded && (
            <small className="text-danger">
              Email content exceeds ${MAX_CHARACTER} characters
            </small>
          )}
        </p>
      </ModalBody>

      <ModalFooter>
        <Button
          color="primary"
          onClick={handleSubmit}
          disabled={templateContent.trim() === "" || isContentLengthExceeded}
        >
          Save Changes
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};
