import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  InputGroup,
  InputGroupAddon,
  Button,
  Input,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { useEffect, useState } from "react";
import { getAllStakeTx } from "services/api";
import { formatDate } from "services/help";
import moment from "moment";

const Transactions = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [buyData, setBuyData] = useState([]);
  const [filteredTx, setFilteredTx] = useState([]);
  const [filterDate, setFilterDate] = useState({ start: "", end: "" });

  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let data = {
          page: pageNumber,
          limit: 10,
        };
        if (filterDate.start && filterDate.end) {
          data.dateRange = {
            start: moment(filterDate.start).startOf("day").toISOString(),
            end: moment(filterDate.end).endOf("day").toISOString(),
          };
        }
        const output = await getAllStakeTx(data);
        setBuyData(output);

        const filtered = output?.data?.docs.filter((transaction) => {
          const searchLower = searchTerm.toLowerCase();
          return (
            formatDate(transaction.createdAt)
              .toLowerCase()
              .includes(searchLower) ||
            transaction.username.toLowerCase().includes(searchLower) ||
            transaction.depositWallet?.toLowerCase().includes(searchLower) ||
            transaction.wallet?.toLowerCase().includes(searchLower) ||
            transaction.transactionHash?.toLowerCase().includes(searchLower) ||
            transaction.status?.toLowerCase().includes(searchLower) ||
            transaction.token.toString() === searchTerm
          );
        });
        setFilteredTx(filtered);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [filterDate, pageNumber, searchTerm]);

  console.log("Stake data : ", buyData);

  const trimAddress = (address) => {
    if (!address || typeof address !== "string") {
      return "";
    }
    if (address?.length <= 15) {
      return address;
    } else {
      return `${address?.slice(0, 10)}...${address?.slice(-5)}`;
    }
  };

  const handleDateChange = (e) => {
    setFilterDate({
      ...filterDate,
      [e.target.name]: e.target.value,
    });
  };

  // const handleBuyMethodChange = (method) => {
  //   setBuyMethod(method === "All" ? "" : method);
  // };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row">
                  <h3 className="mb-0">Stake Management</h3>
                  <InputGroup className="ml-auto" style={{ maxWidth: "400px" }}>
                    <Input
                      placeholder="Search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <InputGroupAddon addonType="append">
                      <Button color="primary">Search</Button>
                    </InputGroupAddon>
                  </InputGroup>
                  {/* <InputGroup className="ml-2" style={{ maxWidth: "400px" }}>
                    <Input
                      type="date"
                      name="start"
                      value={filterDate.start}
                      onChange={handleDateChange}
                      placeholder="Start Date"
                    />
                    <Input
                      type="date"
                      name="end"
                      value={filterDate.end}
                      onChange={handleDateChange}
                      placeholder="End Date"
                    />
                    <InputGroupAddon addonType="prepend">
                      <Button color="primary">Date Range</Button>
                    </InputGroupAddon>
                  </InputGroup> */}
                  {/* <UncontrolledDropdown className="ml-2">
                    <DropdownToggle caret color="primary">
                      Buy Method
                    </DropdownToggle>
                    <DropdownMenu>
                      {["All", "USD", "ETH", "BTC", "BNB"].map((method) => (
                        <DropdownItem
                          key={method}
                          onClick={() => handleBuyMethodChange(method)}
                        >
                          {method}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown className="ml-2">
                    <DropdownToggle caret color="primary">
                      Download Report
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem>CSV</DropdownItem>
                      <DropdownItem>Excel</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown> */}
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Username</th>
                    <th scope="col">Time</th>
                    <th scope="col">Wallet</th>
                    <th scope="col">Durations</th>
                    <th scope="col">Tokens</th>
                    <th scope="col">Transaction Hash</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredTx && filteredTx.length > 0 ? (
                    filteredTx.map((transaction) => (
                      <tr key={transaction.id}>
                        <td>{transaction.username}</td>
                        <td>{formatDate(transaction.createdAt)}</td>
                        <td>
                          {trimAddress(
                            transaction?.depositWallet ?? transaction?.wallet
                          )}
                        </td>
                        <td>{transaction.durations}</td>
                        <td>{transaction.token} CUCC</td>
                        <td>{trimAddress(transaction.transactionHash)}</td>
                        <td>
                          <Badge
                            color={
                              transaction.status === "completed"
                                ? "success"
                                : "warning"
                            }
                            className="badge-dot mr-4"
                          >
                            <i className="" />
                            {transaction.status}
                          </Badge>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7">No transactions found</td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem disabled={pageNumber === 1}>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setPageNumber((prev) => Math.max(prev - 1, 1));
                        }}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    {Array.from(
                      { length: buyData?.data?.totalPages || 1 },
                      (_, index) => (
                        <PaginationItem
                          key={index}
                          active={pageNumber === index + 1}
                        >
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => {
                              e.preventDefault();
                              setPageNumber(index + 1);
                            }}
                          >
                            {index + 1}
                          </PaginationLink>
                        </PaginationItem>
                      )
                    )}
                    <PaginationItem
                      disabled={pageNumber === buyData?.data?.totalPages}
                    >
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setPageNumber((prev) =>
                            Math.min(prev + 1, buyData?.data?.totalPages)
                          );
                        }}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Transactions;
