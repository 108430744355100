import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  InputGroup,
  InputGroupAddon,
  Button,
  Input,
} from "reactstrap";
import Header from "components/Headers/Header.js";
import { useEffect, useState } from "react";
import { getAllUsers, deleteUser } from "services/api";
import {
  formatDate,
  swalFire,
  handleCSVDownload,
  handleExcelDownload,
  formatDOB,
} from "services/help";
import Swal from "sweetalert2";
import UserModal from "views/modal/UserModal";
import axios from "axios";
import moment from "moment";
import { trimContent } from "services/help";

const UserManagement = () => {
  const [usersData, setUsersData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [initialDate, setInitialDate] = useState({ start: "", end: "" });
  const [filterDate, setFilterDate] = useState({ start: "", end: "" });
  // const [verify, setVerify] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [refetch, setRefetch] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const desiredFields = [
    "username",
    "email",
    "firstname",
    "lastname",
    "dob",
    // "verify",
    "kycDocument",
  ];

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let data = {
          // verify: verify === true ? "true" : verify === false ? "false" : "",
          page: pageNumber,
          limit: 10,
        };

        if (filterDate.start !== "" && filterDate.end !== "") {
          data.dobRange = filterDate;
        }

        const output = await getAllUsers(data);
        console.log("get all users from api : ", output);

        setUsersData(output);

        const filtered = output.docs.filter(
          (user) =>
            user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.firstname.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.lastname.toLowerCase().includes(searchTerm.toLowerCase()) ||
            formatDate(user.dob).includes(searchTerm.toLowerCase())
        );
        setFilteredUsers(filtered);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [refetch, filterDate, pageNumber, searchTerm, modalOpen]);

  const delteUserById = async (userId) => {
    try {
      const output = await deleteUser(userId);
      if (output) {
        setRefetch(!refetch);
        swalFire("success", output.message);
      }
    } catch (e) {
      console.log("Error : ", e);
      alert(e);
    }
  };

  const handleDownload = async (doc) => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/download-doc?keydoc=${doc}`,
        method: "GET",
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.jpg");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      if (error.response) {
        swalFire("error", "KYC document not available.");
      } else if (error.request) {
        swalFire(
          "error",
          "No response received from the server. Please check your connection."
        );
      } else {
        swalFire("error", "Error in setting up the request.");
      }
    }
  };

  useEffect(() => {
    if (!modalOpen) {
      setSelectedUser(null);
    }
  }, [modalOpen]);

  return (
    <>
      <Header />

      <Container className="mt--7" fluid>
        {selectedUser && (
          <UserModal
            isOpen={modalOpen}
            toggle={toggleModal}
            user={selectedUser}
            setRefetch={setRefetch}
          />
        )}

        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row">
                  <h3 className="mb-0">User Management</h3>
                  <InputGroup className="ml-auto" style={{ maxWidth: "400px" }}>
                    <Input
                      placeholder="Search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </InputGroup>
                  <InputGroup className="ml-2" style={{ maxWidth: "400px" }}>
                    <Input
                      type="date"
                      placeholder="Start Date"
                      value={initialDate.start}
                      onChange={(e) =>
                        setInitialDate({
                          ...initialDate,
                          start: e.target.value,
                        })
                      }
                    />
                    <Input
                      type="date"
                      placeholder="End Date"
                      value={initialDate.end}
                      onChange={(e) =>
                        setInitialDate({
                          ...initialDate,
                          end: e.target.value,
                        })
                      }
                    />
                    <InputGroupAddon addonType="prepend">
                      <Button
                        color="primary"
                        onClick={() => {
                          setFilterDate({
                            start: moment(initialDate.start)
                              .startOf("day")
                              .toISOString(),
                            end: moment(initialDate.end)
                              .endOf("day")
                              .toISOString(),
                          });

                          // setFilterDate({
                          //   start: moment(initialDate.start)
                          //     .startOf("day")
                          //     .toISOString(),
                          //   end: moment(initialDate.end)
                          //     .endOf("day")
                          //     .toISOString(),
                          // });
                        }}
                      >
                        Search
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                  {/* <UncontrolledDropdown className="ml-2">
                    <DropdownToggle caret color="primary">
                      Verify
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => setVerify("")}>
                        All
                      </DropdownItem>
                      <DropdownItem onClick={() => setVerify(false)}>
                        Pending
                      </DropdownItem>
                      <DropdownItem onClick={() => setVerify(true)}>
                        Completed
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown> */}
                  <UncontrolledDropdown className="ml-2">
                    <DropdownToggle caret color="primary">
                      Download Report
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() => {
                          if (filteredUsers && filteredUsers.length === 0) {
                            swalFire("error", "No data available");
                            return;
                          }
                          handleCSVDownload(
                            filteredUsers,
                            desiredFields,
                            "Users.csv"
                          );
                        }}
                      >
                        CSV
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          if (filteredUsers.length === 0) {
                            swalFire("error", "No data available");
                            return;
                          }
                          handleExcelDownload(
                            filteredUsers,
                            desiredFields,
                            "Users.xlsx"
                          );
                        }}
                      >
                        Excel
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Sn</th>
                    <th scope="col">Username</th>
                    <th scope="col">Email</th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">DOB</th>
                    {/* <th scope="col">Verify</th> */}
                    {/* <th scope="col">Document</th> */}
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {filteredUsers && filteredUsers.length > 0 ? (
                    filteredUsers.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <th scope="row">
                          <Media>
                            <span className="mb-0 text-sm">
                              {item.username}
                            </span>
                          </Media>
                        </th>
                        <td>{item.email}</td>
                        <td>{trimContent(item.firstname, 30)}</td>
                        <td>{trimContent(item.lastname, 30)}</td>
                        <td>{formatDOB(item.dob)}</td>
                        {/* <td>
                          <Badge color="" className="badge-dot mr-4">
                            <i
                              className={`bg-${
                                item.verify === true ? "success" : "warning"
                              }`}
                            />
                            {item.verify === true ? "completed" : "pending"}
                          </Badge>
                        </td> */}
                        {/* <td>
                          <span className="text-danger mr-2">
                            <i
                              onClick={() => handleDownload(item.kycDocument)}
                              className="ni ni-cloud-download-95"
                              style={{ fontSize: "24px" }}
                            />
                          </span>
                        </td> */}
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                onClick={() => {
                                  setSelectedUser(item);
                                  toggleModal();
                                }}
                              >
                                View & Update
                              </DropdownItem>
                              <DropdownItem
                                onClick={(e) => {
                                  e.preventDefault();
                                  Swal.fire({
                                    title: "Are you sure?",
                                    text: "You won't be able to revert this user!",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Yes, delete it!",
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      delteUserById(item._id);
                                    }
                                  });
                                }}
                              >
                                Delete
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="9" className="text-center">
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>

              {filteredUsers && filteredUsers.length > 0 ? (
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem>
                        <PaginationLink
                          className="disabled"
                          href="#pablo"
                          onClick={(e) => {
                            e.preventDefault();
                            setPageNumber(
                              pageNumber > 1 ? pageNumber - 1 : pageNumber
                            );
                          }}
                          tabIndex="-1"
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      {Array.from(
                        { length: usersData?.totalPages },
                        (_, index) => (
                          <PaginationItem
                            key={index}
                            className={pageNumber === index + 1 ? "active" : ""}
                          >
                            <PaginationLink
                              href="#pablo"
                              onClick={(e) => {
                                e.preventDefault();
                                setPageNumber(index + 1);
                              }}
                            >
                              {index + 1}
                            </PaginationLink>
                          </PaginationItem>
                        )
                      )}
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => {
                            e.preventDefault();
                            setPageNumber(
                              pageNumber < usersData?.totalPages
                                ? pageNumber + 1
                                : pageNumber
                            );
                          }}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              ) : null}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default UserManagement;
