import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { updateUserByAdmin } from "services/api";
import { swalFire } from "services/help";
import defaultProfileImage from "assets/avatar.png";

const UserModal = ({ user, isOpen, toggle, setRefetch }) => {
  // State to hold updated user data
  const [updatedUser, setUpdatedUser] = useState(user);
  const [originalUser, setOriginalUser] = useState(user);
  const [isFormValid, setIsFormValid] = useState(true);
  const [maxDate, setMaxDate] = useState("");

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear() - 16; // Subtract 16 years from the current year
    const month = ("0" + (today.getMonth() + 1)).slice(-2);
    const day = ("0" + today.getDate()).slice(-2);
    setMaxDate(`${year}-${month}-${day}`);
  }, []);

  useEffect(() => {
    setUpdatedUser(user);
    setOriginalUser(user);
  }, [user]);

  // Function to handle user data updates
  const handleUpdate = async () => {
    if (!hasChanges() || !isFormValid) {
      swalFire("warning", "No changes made or invalid input.");
      return;
    }

    try {
      const output = await updateUserByAdmin(updatedUser);
      if (output) {
        swalFire("success", output.message);
        setRefetch(true);
        toggle();
      }
    } catch (e) {
      console.log(e);
    }
  };

  // Function to handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (value[0] === " ") {
      return;
    }
    const newUpdatedUser = { ...updatedUser, [name]: value };
    setUpdatedUser(newUpdatedUser);
    validateForm(newUpdatedUser);
  };

  // Function to check if there are any changes
  const hasChanges = () => {
    return JSON.stringify(originalUser) !== JSON.stringify(updatedUser);
  };

  // Function to validate form inputs
  const validateForm = (user) => {
    const isValid =
      user.username &&
      user.email &&
      user.firstname &&
      user.lastname &&
      user.dob;
    setIsFormValid(isValid);
  };

  const formatDateToInputValue = (dateString) => {
    const date = new Date(dateString);
    const pad = (num) => (num < 10 ? `0${num}` : num);
    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
      date.getDate()
    )}`;
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <img
          src={user.profileImage || defaultProfileImage}
          alt="Profile"
          className="rounded-circle"
          style={{ width: "50px", height: "50px", marginRight: "10px" }}
        />
        View & Update User
      </ModalHeader>
      <ModalBody>
        {/* Display user data */}
        <div className="form-group">
          <label>Username: </label>
          <input
            type="text"
            className="form-control"
            name="username"
            disabled={true}
            value={updatedUser.username}
            onChange={handleInputChange}
          />
          {!updatedUser.username && (
            <small className="text-danger">Username is required</small>
          )}
        </div>

        <div className="form-group">
          <label>Email: </label>
          <input
            type="email"
            disabled={true}
            className="form-control"
            name="email"
            value={updatedUser.email}
            onChange={handleInputChange}
          />
          {!updatedUser.email && (
            <small className="text-danger">Email is required</small>
          )}
        </div>

        <div className="form-group">
          <label>First Name : </label>
          <input
            type="text"
            className="form-control"
            name="firstname"
            value={updatedUser.firstname}
            onChange={handleInputChange}
          />
          {!updatedUser.firstname && (
            <small className="text-danger">First Name is required</small>
          )}
        </div>

        <div className="form-group">
          <label>Last Name: </label>
          <input
            type="text"
            className="form-control"
            name="lastname"
            value={updatedUser.lastname}
            onChange={handleInputChange}
          />
          {!updatedUser.lastname && (
            <small className="text-danger">Last Name is required</small>
          )}
        </div>

        <div className="form-group">
          <label>Date of Birth: </label>
          <input
            type="date"
            className="form-control"
            name="dob"
            value={formatDateToInputValue(updatedUser?.dob)}
            onChange={handleInputChange}
            max={maxDate}
          />
          {!updatedUser.dob && (
            <small className="text-danger">Date of Birth is required</small>
          )}
        </div>

        {/* <div className="form-group">
          <label>Verify: </label>
          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              className="custom-control-input"
              id="verifySwitch"
              name="verify"
              checked={updatedUser.verify}
              onChange={() =>
                setUpdatedUser({ ...updatedUser, verify: !updatedUser.verify })
              }
            />
            <label className="custom-control-label" htmlFor="verifySwitch">
              {updatedUser.verify ? "Verified" : "Not Verified"}
            </label>
          </div>
        </div> */}
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={handleUpdate}
          disabled={!isFormValid || !hasChanges()}
        >
          Update
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UserModal;
