import Index from "views/Index.js";
import Login from "views/pages/Login.js";
import Users from "views/pages/Users.js";
import Transactions from "views/pages/Transactions.js";
import EmailTemplates from "views/pages/EmailTemplates.js";
import Stake from "views/pages/Stake.js";
import FAQ from "views/pages/FAQ";
import Profile from "views/pages/Profile";
import Enquiry from "views/pages/Inquiry";
import Content from "views/pages/Content";
import { StakeFunctions } from "views/pages/StakeFunctions";
import Stability from "views/pages/Stability";
import SaleStakeManage from "views/pages/SaleStakeManage";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: <Index />,
    layout: "/admin",
  },

  {
    path: "/user-manage",
    name: "Users",
    icon: "ni ni-single-02 text-primary",
    component: <Users />,
    layout: "/admin",
  },

  {
    path: "/transactions",
    name: "Transactions",
    icon: "fa fa-exchange text-blue",
    component: <Transactions />,
    layout: "/admin",
  },

  {
    path: "/stake",
    name: "Staking",
    icon: "fas fa-money-bill-wave text-primary", // Example icon for staking
    component: <Stake />,
    layout: "/admin",
  },
  {
    path: "/emails",
    name: "Email",
    icon: "fa fa-envelope text-blue",
    component: <EmailTemplates />,
    layout: "/admin",
  },

  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: <Login />,
    layout: "/auth",
  },

  {
    path: "/faq",
    name: "FAQ",
    icon: "ni ni-chat-round text-blue",
    component: <FAQ />,
    layout: "/admin",
  },

  {
    path: "/profile",
    name: "Profile",
    icon: "fa fa-envelope text-blue",
    component: <Profile />,
    layout: "/admin",
  },

  {
    path: "/inquiry",
    name: "Inquiry",
    icon: "fa fa-envelope text-blue",
    component: <Enquiry />,
    layout: "/admin",
  },

  {
    path: "/content-management",
    name: "Website Content",
    icon: "fa fa-envelope text-blue",
    component: <Content />,
    layout: "/admin",
  },
  {
    path: "/stake-functions",
    name: "Stake Functions",
    icon: "fas fa-money-bill-wave text-primary",
    component: <StakeFunctions />,
    layout: "/admin",
  },
  {
    path: "/manage-coin",
    name: "Manage Coin",
    icon: "fa fa-envelope text-blue",
    component: <Stability />,
    layout: "/admin",
  },
  {
    path: "/sale-stake-manage",
    name: "Sale & Stake Manage",
    icon: "fa fa-envelope text-blue",
    component: <SaleStakeManage />,
    layout: "/admin",
  },
];
export default routes;
