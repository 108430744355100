import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardHeader, Table, Badge, Row, Col } from "reactstrap";
import { getAllBuyTx } from "services/api";
import { formatDate } from "services/help";
// import { TopHolders } from "./TopHolders";

const RecentBuy = () => {
  const navigate = useNavigate();
  const [buyData, setBuyData] = useState([]);

  const trimAddress = (address) => {
    if (!address || typeof address !== "string") {
      return "";
    }
    if (address.length <= 15) {
      return address;
    } else {
      return address.slice(0, 10) + "..." + address.slice(-5);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let data = {
          page: 1,
          limit: 5,
        };
        const output = await getAllBuyTx(data);

        setBuyData(output?.data?.data?.docs);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle errors here, such as displaying an error message or setting a state variable
      }
    };

    fetchData(); // Call the fetchData function
  }, []);

  console.log("setBuyData : ", buyData);

  const renderTableRows = () => {
    return buyData?.map((item, index) => (
      <tr key={index}>
        <th scope="row">{item.username}</th>
        <td>{formatDate(item.createdAt)}</td>
        {/* <td>{item.createdAt}</td> */}
        {/* <td>{item.buyMethod}</td> */}
        <td>{trimAddress(item.depositWallet)}</td>
        <td>
          {item.amount} {item.buyMethod}
        </td>
        <td>{item.token} CUCC</td>

        <td>{trimAddress(item.transactionHash)}</td>
        <td>
          <Badge
            color=""
            className="badge-dot mr-4"
            style={{ paddingTop: "0", paddingBottom: "0" }}
          >
            <i
              className={
                item.status === "completed" ? "bg-success" : "bg-warning"
              }
            />
            {item.status == "completed" ? "Completed" : "Fails"}
          </Badge>
        </td>
      </tr>
    ));
  };

  return (
    <Row className="mt-5">
      <Col className="mb-5 mb-xl-0" xl="12">
        <Card className="shadow">
          <CardHeader className="border-0">
            <Row className="align-items-center">
              <div className="col">
                <h3 className="mb-0">Recent Transactions</h3>
              </div>
              <div className="col text-right">
                <Button
                  color="primary"
                  href="#pablo"
                  onClick={() => {
                    navigate("/admin/transactions");
                  }}
                  size="sm"
                >
                  See all transactions
                </Button>
              </div>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">Username</th>
                <th scope="col">Date & Time</th>
                {/* <th scope="col">Buy Method</th> */}
                <th scope="col">Deposit Wallet</th>
                <th scope="col">Amount</th>
                <th scope="col">Tokens</th>
                <th scope="col">Transaction Hash</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>{renderTableRows()}</tbody>
          </Table>
        </Card>
      </Col>
      {/* <TopHolders /> */}
    </Row>
  );
};

export default RecentBuy;
