import React, { useState } from "react";
import {
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
} from "reactstrap";

import { updateFaq } from "services/api";
import { swalFire } from "services/help";

export const FAQUpdateModal = ({ faq, isOpen, toggle }) => {
  const [oldQuestion, setOldQuestion] = useState(faq?.question ?? "");
  const [oldAnswer, setOldAnswer] = useState(faq?.answer ?? "");

  const [question, setQuestion] = useState(faq?.question ?? "");
  const [answer, setAnswer] = useState(faq?.answer ?? "");

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value === " ") {
      return;
    }
    if (name === "question") setQuestion(value);
    else if (name === "answer") setAnswer(value);
  };

  const handleSubmit = async () => {
    // Here you can perform form submission logic
    const formData = {
      question,
      answer,
    };

    try {
      let output;

      let data = { ...formData, _id: faq._id };
      console.log("data : ", data, typeof data);
      output = await updateFaq(data);

      console.log("outputoutput : ", output);
      if (output?.faq) {
        swalFire("success", "Faq updated successfully");
      } else {
        swalFire("error", "Unable to update Faq");
      }
    } catch (e) {
      console.log("Error : ", e);
    }

    setAnswer("");
    setQuestion("");
    setOldAnswer("");
    setOldQuestion("");
    toggle(); // Close the modal after submission
  };

  console.log("answer and question : ", answer, question);
  console.log("FAQ : ", faq);

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Update FAQ</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="question">Question</Label>
          <Input
            type="text"
            name="question"
            id="question"
            value={question ? question : faq?.question}
            onChange={handleChange}
            maxLength={150}
          />
          {!question && !faq?.question && (
            <small className="text-danger">Question filed is required</small>
          )}
        </FormGroup>
        <FormGroup>
          <Label for="answer">Answer</Label>
          <Input
            type="textarea"
            name="answer"
            id="answer"
            rows="10"
            value={answer ? answer : faq?.answer}
            onChange={handleChange}
            maxLength={600}
          />
          {!answer && !faq?.answer && (
            <small className="text-danger">Answer filed is required</small>
          )}
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={handleSubmit}
          disabled={answer === oldAnswer && question === oldQuestion}
        >
          Save Changes
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            setAnswer("");
            setQuestion("");
            setOldAnswer("");
            setOldAnswer("");
            toggle();
          }}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};
