import { Contract, ethers } from "ethers";
import abi from "./ABI/abi";
import { governenceABI } from "./ABI/GovernanceABI";
import { reserveABI } from "./ABI/ReserveABI";
import { tokenABI } from "./ABI/TokenABI";
import { getContractError } from "./help";
import { SaleABI } from "./ABI/SaleABI";
import { StakeABI } from "./ABI/StakingABI";

export const ReserveContractAddress =
  "0xa38dF4561844967d8aA44BCb4bC3b779497b87e6";
export const GovernencedContractAddress =
  "0x98362c41c67caDDf305c09c83d0D2Fb4f527e5b2";
// export const LinkContractAddress = "0x514910771AF9Ca656af840dff83E8264EcF986CA";
export const EthereumContractAddress =
  "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE";
export const ETH_USD_PriceFeeds = "0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419";

export const CopperAddress1 = "0x855F7961F2Af40E441E34B84A8D48a632891032f";
export const USDTAddress1 = "0xdAC17F958D2ee523a2206206994597C13D831ec7";
export const WBTCAddress1 = "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599";
export const PresaleAddress1 = "0xB6f8CC83D56AAC86E23F8aF7793E02EFFb4628E8";
export const StakingContractAddress1 =
  "0x2389F5D39b18732D8D68DD70625331c9B049Ab75";

const RPC_URL =
  "https://eth-mainnet.g.alchemy.com/v2/HZGTgTPiqB408bYkAdUFeDOTedqp4DBA";

// Initialize provider
const provider = new ethers.providers.JsonRpcProvider(RPC_URL);

async function getReadContract(CONTRACT_ADDRESS, CONTRACT_ABI) {
  // Create a new contract instance
  const contract = new ethers.Contract(
    CONTRACT_ADDRESS,
    CONTRACT_ABI,
    provider
  );
  return contract;
}

export async function getStakePlans() {
  const contractInstance = await getReadContract(StakingContractAddress1, abi);
  let stakePlans = await contractInstance.getAllStakingPlans();
  return stakePlans;
}

export async function getOwner() {
  const contractInstance = await getReadContract(StakingContractAddress1, abi);
  let owner = await contractInstance.owner();
  return owner;
}

export async function addNewStakePlan(duration, apr, signer) {
  console.log("addNewStakePlan called from contractHelper");
  const contractInstance = new Contract(StakingContractAddress1, abi, signer);

  try {
    // Estimate gas without setting it manually first
    const gasEstimate = await contractInstance.estimateGas.addStakingPlan(
      duration,
      apr
    );

    // Create the transaction with the estimated gas
    const transaction = await contractInstance.addStakingPlan(duration, apr, {
      gasLimit: gasEstimate,
    });

    await transaction.wait(); // Wait for the transaction to be mined
    return transaction;
  } catch (error) {
    console.error("Transaction error: ", error);
    if (error.toString().includes("user rejected transaction")) {
      console.log("AA");
      return { msg: "User rejected transaction", status: "fail" };
    }

    if (error?.data?.message) {
      if (error?.data?.message.includes("err: insufficient funds for gas")) {
        return "Insufficient ETH Amount";
      } else {
        return error?.data?.message;
      }
    }

    let errorMessage = await getContractError(error);
    if (errorMessage) {
      return errorMessage;
    } else {
      return "Something went wrong, check gas fee";
    }
  }
}

export async function updateStakePlan(id, duration, apr, signer) {
  const contractInstance = new Contract(StakingContractAddress1, abi, signer);

  try {
    const gasLimit = await contractInstance.estimateGas.updateStakingPlan(
      id,
      duration,
      apr,
      {
        gasLimit: 3000000,
      }
    );

    const transaction = await contractInstance.updateStakingPlan(
      id,
      duration,
      apr,
      {
        gasLimit: gasLimit, // Manually set a gas limit
        // gasLimit: 3000000,
      }
    );
    await transaction.wait(); // Wait for the transaction to be mined
    return transaction;
  } catch (error) {
    console.log("error : " + error);
    if (error.toString().includes("user rejected transaction")) {
      console.log("AA");
      return { msg: "User rejected transaction", status: "fail" };
    }

    if (error?.data?.message) {
      if (error?.data?.message.includes("err: insufficient funds for gas")) {
        console.log("A");
        return "Insufficient ETH Amount";
      } else {
        console.log("B");
        return error?.data?.message;
      }
    }
    let errorMessage = await getContractError(error);
    if (errorMessage) {
      console.log("C");
      return errorMessage;
    } else {
      return "something went wrong, check gass fee";
    }
  }
}

export async function deleteStakePlan(id, signer) {
  const contractInstance = new Contract(StakingContractAddress1, abi, signer);

  try {
    const gasLimit = await contractInstance.estimateGas.removeStakingPlan(
      id,

      {
        gasLimit: 3000000,
      }
    );

    const transaction = await contractInstance.removeStakingPlan(
      id,

      {
        gasLimit: gasLimit, // Manually set a gas limit
        // gasLimit: 3000000,
      }
    );
    await transaction.wait(); // Wait for the transaction to be mined
    return transaction;
  } catch (error) {
    console.log("error : " + error);
    if (error.toString().includes("user rejected transaction")) {
      console.log("AA");
      return { msg: "User rejected transaction", status: "fail" };
    }

    if (error?.data?.message) {
      if (error?.data?.message.includes("err: insufficient funds for gas")) {
        console.log("A");
        return "Insufficient ETH Amount";
      } else {
        console.log("B");
        return error?.data?.message;
      }
    }
    let errorMessage = await getContractError(error);
    if (errorMessage) {
      console.log("C");
      return errorMessage;
    } else {
      return "something went wrong, check gass fee";
    }
  }
}

export async function getStablecoinDashboard() {
  console.log("getStablecoinDashboard just called");
  const data = {
    totalSupply: 0,
    supplyChangeCount: 0,
    price: 0,
    amount: 0,
    link: 0,
    governenceCUCC: 0,
  };

  try {
    const copperContract = await getReadContract(CopperAddress1, tokenABI);
    const goverContract = await getReadContract(
      GovernencedContractAddress,
      governenceABI
    );
    // const linkContract = await getReadContract(LinkContractAddress, tokenABI);

    // Governace balance
    let goverBalance = await copperContract.balanceOf(
      GovernencedContractAddress
    );
    let updateGovBalace = ethers.utils.formatEther(goverBalance);
    // console.log("Balance of Governace RAHUL : ", updateGovBalace);

    let copperTotalSupply = await copperContract.totalSupply();
    let supplyChangeCount = await goverContract.supplyChangeCount();
    let price = await goverContract.LivePrices();
    let amount = await goverContract.ReservedAmount();
    // let link = await linkContract.balanceOf(GovernencedContractAddress);
    data.totalSupply = copperTotalSupply;
    data.supplyChangeCount = supplyChangeCount;
    data.price = price;
    data.amount = amount;
    data.governenceCUCC = updateGovBalace;

    console.log("data from contract  : ", data);
    return data;
  } catch (e) {
    console.log(e);
    return data;
  }
}

export async function manuallyRebalance(signer) {
  const contractInstance = new Contract(
    GovernencedContractAddress,
    governenceABI,
    signer
  );

  try {
    const gasLimit = await contractInstance.estimateGas.Rebalance({
      gasLimit: 3000000,
    });

    const transaction = await contractInstance.Rebalance({
      gasLimit: gasLimit, // Manually set a gas limit
      // gasLimit: 3000000,
    });
    await transaction.wait(); // Wait for the transaction to be mined
    return { transaction: transaction, status: "success" };
  } catch (error) {
    console.log("error : " + error);

    if (error.toString().includes("user rejected transaction")) {
      console.log("AA");
      return { msg: "User rejected transaction", status: "fail" };
    }

    if (error?.data?.message) {
      if (error?.data?.message.includes("err: insufficient funds for gas")) {
        console.log("A");
        return { msg: "Insufficient ETH Amount", status: "fail" };
      } else {
        console.log("B");
        return { msg: error?.data?.message, status: "fail" };
      }
    }
    let errorMessage = await getContractError(error);
    if (errorMessage) {
      console.log("C");
      return { msg: errorMessage, status: "fail" };
    } else {
      return { msg: "Somethigns went wrong", status: "fail" };
    }
  }
}

export async function latestPrice(signer, price) {
  const contractInstance = new Contract(
    GovernencedContractAddress,
    governenceABI,
    signer
  );

  try {
    const gasLimit = await contractInstance.estimateGas.setCopperLivePrice(
      price,
      {
        gasLimit: 3000000,
      }
    );

    const transaction = await contractInstance.setCopperLivePrice(price, {
      gasLimit: gasLimit,
    });
    await transaction.wait(); // Wait for the transaction to be mined
    return { transaction: transaction, status: "success" };
  } catch (error) {
    console.log("error : " + error);

    if (error.toString().includes("user rejected transaction")) {
      console.log("AA");
      return { msg: "User rejected transaction", status: "fail" };
    }

    if (error?.data?.message) {
      if (error?.data?.message.includes("err: insufficient funds for gas")) {
        console.log("A");
        return { msg: "Insufficient ETH Amount", status: "fail" };
      } else {
        console.log("B");
        return { msg: error?.data?.message, status: "fail" };
      }
    }
    let errorMessage = await getContractError(error);
    if (errorMessage) {
      console.log("C");
      return { msg: errorMessage, status: "fail" };
    } else {
      return { msg: "Somethigns went wrong", status: "fail" };
    }
  }
}

export async function depositETHSend(signer, amount) {
  const contractInstance = new Contract(
    ReserveContractAddress,
    reserveABI,
    signer
  );

  try {
    const amountInWei = await ethers.utils.parseEther(amount);
    const gasLimit = await contractInstance.estimateGas.deposit(amountInWei, {
      gasLimit: 3000000,
    });

    const transaction = await contractInstance.deposit(
      amountInWei,

      {
        gasLimit: gasLimit,
      }
    );
    await transaction.wait(); // Wait for the transaction to be mined
    return { transaction: transaction, status: "success" };
  } catch (error) {
    console.log("error : " + error);
    if (error.toString().includes("user rejected transaction")) {
      console.log("AA");
      return { msg: "User rejected transaction", status: "fail" };
    }

    if (error?.data?.message) {
      if (error?.data?.message.includes("err: insufficient funds for gas")) {
        console.log("A");
        return { msg: "Insufficient ETH Amount", status: "fail" };
      } else {
        console.log("B");
        return { msg: error?.data?.message, status: "fail" };
      }
    }
    let errorMessage = await getContractError(error);
    if (errorMessage) {
      console.log("C");
      return { msg: errorMessage, status: "fail" };
    } else {
      return { msg: "Somethigns went wrong", status: "fail" };
    }
  }
}

export async function withdrawETHSend(signer, amount) {
  const contractInstance = new Contract(
    ReserveContractAddress,
    reserveABI,
    signer
  );

  try {
    const amountInWei = await ethers.utils.parseEther(amount);

    const gasLimit = await contractInstance.estimateGas.withdraw(amountInWei, {
      gasLimit: 3000000,
    });

    const transaction = await contractInstance.withdraw(
      amountInWei,

      {
        gasLimit: gasLimit,
      }
    );
    await transaction.wait(); // Wait for the transaction to be mined
    return { transaction: transaction, status: "success" };
  } catch (error) {
    console.log("error catch : " + error);

    if (error.toString().includes("user rejected transaction")) {
      console.log("AA");
      return { msg: "User rejected transaction", status: "fail" };
    }

    if (error?.data?.message) {
      if (error?.data?.message.includes("err: insufficient funds for gas")) {
        console.log("A");
        return { msg: "Insufficient ETH Amount", status: "fail" };
      } else {
        console.log("B");
        return { msg: error?.data?.message, status: "fail" };
      }
    }
    let errorMessage = await getContractError(error);
    if (errorMessage) {
      console.log("C");
      return { msg: errorMessage, status: "fail" };
    } else {
      return { msg: "Somethigns went wrong", status: "fail" };
    }
  }
}

export async function mintTokenSend(signer, amount) {
  const contractInstance = new Contract(CopperAddress1, tokenABI, signer);

  try {
    const amountInWei = await ethers.utils.parseEther(amount);

    const gasLimit = await contractInstance.estimateGas.mint(amountInWei, {
      gasLimit: 3000000,
    });

    const transaction = await contractInstance.mint(
      amountInWei,

      {
        gasLimit: gasLimit,
      }
    );
    await transaction.wait(); // Wait for the transaction to be mined
    return { transaction: transaction, status: "success" };
  } catch (error) {
    console.log("error : " + error);

    if (error.toString().includes("user rejected transaction")) {
      console.log("AA");
      return { msg: "User rejected transaction", status: "fail" };
    }

    if (error?.data?.message) {
      if (error?.data?.message.includes("err: insufficient funds for gas")) {
        console.log("A");
        return { msg: "Insufficient ETH Amount", status: "fail" };
      } else {
        console.log("B");
        return { msg: error?.data?.message, status: "fail" };
      }
    }
    let errorMessage = await getContractError(error);
    if (errorMessage) {
      console.log("C");
      return { msg: errorMessage, status: "fail" };
    } else {
      return { msg: "Somethigns went wrong", status: "fail" };
    }
  }
}

export async function getRebalanceHistory() {
  const data = [];
  const maxlengh = 10;

  try {
    const goverContract = await getReadContract(
      GovernencedContractAddress,
      governenceABI
    );

    let supplyChangeCount = Number(await goverContract.supplyChangeCount());
    for (
      let i = supplyChangeCount >= maxlengh ? supplyChangeCount - maxlengh : 0;
      i < supplyChangeCount;
      i++
    ) {
      const supplyChange = await goverContract._supplyChanges(i);

      data.push({
        sn: i + 1,
        // sn:
        //   supplyChangeCount >= maxlengh
        //     ? supplyChangeCount - maxlengh + i - 1
        //     : i,
        amount: Number(supplyChange.amount) / 10 ** 18,
        blocknum: Number(supplyChange.blocknum),
        method: supplyChange.method,
        timestamp: Number(supplyChange.timestamp),
      });
    }

    console.log("supplyChangeCount data from contract  : ", supplyChangeCount);
    return data;
  } catch (e) {
    console.log(e);
    return data;
  }
}

export async function withdrawLinkSend(signer) {
  const contractInstance = new Contract(
    GovernencedContractAddress,
    governenceABI,
    signer
  );

  try {
    const gasLimit = await contractInstance.estimateGas.withdrawLink({
      gasLimit: 3000000,
    });

    const transaction = await contractInstance.withdrawLink({
      gasLimit: gasLimit,
    });
    await transaction.wait(); // Wait for the transaction to be mined
    return { transaction: transaction, status: "success" };
  } catch (error) {
    console.log("error : " + error);

    if (error.toString().includes("user rejected transaction")) {
      console.log("AA");
      return { msg: "User rejected transaction", status: "fail" };
    }

    if (error?.data?.message) {
      if (error?.data?.message.includes("err: insufficient funds for gas")) {
        console.log("A");
        return { msg: "Insufficient ETH Amount", status: "fail" };
      } else {
        console.log("B");
        return { msg: error?.data?.message, status: "fail" };
      }
    }
    let errorMessage = await getContractError(error);
    if (errorMessage) {
      console.log("C");
      return { msg: errorMessage, status: "fail" };
    } else {
      return { msg: "Somethigns went wrong", status: "fail" };
    }
  }
}

export async function getSaleStakeData() {
  try {
    const saleContract = await getReadContract(PresaleAddress1, SaleABI);
    const usdtContract = await getReadContract(USDTAddress1, tokenABI);
    const wbtcContract = await getReadContract(WBTCAddress1, tokenABI);
    const cuccContract = await getReadContract(CopperAddress1, tokenABI);

    // Copper balance
    let cuccOnICO = await saleContract.presaleTokens();

    // Ether balance
    const ethBalance = await provider.getBalance(PresaleAddress1);

    // USDT balance
    let usdtBalance = await usdtContract.balanceOf(PresaleAddress1);

    // WBTC balance
    let wbtcBalance = await wbtcContract.balanceOf(PresaleAddress1);

    // Copper balance on Staking contract
    let cuccBalanceOnStake = await cuccContract.balanceOf(
      StakingContractAddress1
    );

    const data = {
      CUCC_ON_ICO: ethers.utils.formatEther(cuccOnICO),
      ETH_ON_ICO: ethers.utils.formatEther(ethBalance),
      USDT_ON_ICO: ethers.utils.formatUnits(usdtBalance, 6), // 6 decimals
      WBTC_ON_ICO: ethers.utils.formatUnits(wbtcBalance, 8), // 8 decimals
      CUCC_ON_STAKE: ethers.utils.formatEther(cuccBalanceOnStake),
    };

    return data;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function withdrawICOSend(signer, tokenAddress, amount) {
  const contractInstance = new Contract(PresaleAddress1, SaleABI, signer);
  const address = await signer.getAddress();

  const owner = await contractInstance.owner();
  if (address.toLowerCase() !== owner.toLowerCase()) {
    console.log("You are not the owner of this contract");
    return {
      msg: "Please connect with owner wallet",
      status: "fail",
    };
  }

  try {
    if (tokenAddress.toUpperCase() === EthereumContractAddress.toUpperCase()) {
      console.log("For ETH");

      const amountInWei = await ethers.utils.parseUnits(amount.toString(), 18);

      const gasLimit = await contractInstance.estimateGas.withdrawETH(
        amountInWei,
        {
          gasLimit: 3000000,
        }
      );

      const transaction = await contractInstance.withdrawETH(amountInWei, {
        gasLimit: gasLimit,
      });

      await transaction.wait(); // Wait for the transaction to be mined
      return { transaction: transaction, status: "success" };
    } else {
      console.log("Withdraw Tokens : ", tokenAddress, amount);
      let amountInWei = 0;

      // when token Address is USDT
      if (tokenAddress.toUpperCase() === USDTAddress1.toUpperCase()) {
        amountInWei = await ethers.utils.parseUnits(amount.toString(), 6);
      }

      // when token Address is WBTC
      if (tokenAddress.toUpperCase() === WBTCAddress1.toUpperCase()) {
        amountInWei = await ethers.utils.parseUnits(amount.toString(), 8);
      }

      // when token address is Copper
      if (tokenAddress.toUpperCase() === CopperAddress1.toUpperCase()) {
        amountInWei = await ethers.utils.parseUnits(amount.toString(), 18);
      }

      const gasLimit = await contractInstance.estimateGas.withdrawTokens(
        tokenAddress,
        amountInWei,
        {
          gasLimit: 3000000,
        }
      );

      const transaction = await contractInstance.withdrawTokens(
        tokenAddress,
        amountInWei,

        {
          gasLimit: gasLimit,
        }
      );
      await transaction.wait(); // Wait for the transaction to be mined
      return { transaction: transaction, status: "success" };
    }
  } catch (error) {
    console.log("error : " + error);

    if (error.toString().includes("user rejected transaction")) {
      console.log("AA");
      return { msg: "User rejected transaction", status: "fail" };
    }

    if (error?.data?.message) {
      if (error?.data?.message.includes("err: insufficient funds for gas")) {
        console.log("A");
        return { msg: "Insufficient ETH Amount", status: "fail" };
      } else {
        console.log("B");
        return { msg: error?.data?.message, status: "fail" };
      }
    }
    let errorMessage = await getContractError(error);
    if (errorMessage) {
      console.log("C");
      return { msg: errorMessage, status: "fail" };
    } else {
      return { msg: "Somethigns went wrong", status: "fail" };
    }
  }
}

export async function withdrawStakeSend(signer, amount) {
  const contractInstance = new Contract(
    StakingContractAddress1,
    StakeABI,
    signer
  );

  const address = await signer.getAddress();

  const owner = await contractInstance.owner();
  if (address.toLowerCase() !== owner.toLowerCase()) {
    console.log("You are not the owner of this contract");
    return {
      msg: "Please connect with owner wallet",
      status: "fail",
    };
  }

  try {
    const amountInWei = await ethers.utils.parseUnits(amount.toString(), 18);

    const gasLimit = await contractInstance.estimateGas.withdrawCrypto(
      CopperAddress1,
      amountInWei,
      {
        gasLimit: 3000000,
      }
    );

    const transaction = await contractInstance.withdrawCrypto(
      CopperAddress1,
      amountInWei,

      {
        gasLimit: gasLimit,
      }
    );
    await transaction.wait(); // Wait for the transaction to be mined
    return { transaction: transaction, status: "success" };
  } catch (error) {
    console.log("error : " + error);

    if (error.toString().includes("user rejected transaction")) {
      console.log("AA");
      return { msg: "User rejected transaction", status: "fail" };
    }

    if (error?.data?.message) {
      if (error?.data?.message.includes("err: insufficient funds for gas")) {
        console.log("A");
        return { msg: "Insufficient ETH Amount", status: "fail" };
      } else {
        console.log("B");
        return { msg: error?.data?.message, status: "fail" };
      }
    }
    let errorMessage = await getContractError(error);
    if (errorMessage) {
      console.log("C");
      return { msg: errorMessage, status: "fail" };
    } else {
      return { msg: "Somethigns went wrong", status: "fail" };
    }
  }
}

export async function withdrawCUCCFromGovSend(signer, amount) {
  const contractInstance = new Contract(
    GovernencedContractAddress,
    governenceABI,
    signer
  );

  try {
    const amountInWei = await ethers.utils.parseEther(amount);

    const gasLimit = await contractInstance.estimateGas.withdrawCopper(
      amountInWei,
      {
        gasLimit: 3000000,
      }
    );

    const transaction = await contractInstance.withdrawCopper(amountInWei, {
      gasLimit: gasLimit,
    });
    await transaction.wait(); // Wait for the transaction to be mined
    return { transaction: transaction, status: "success" };
  } catch (error) {
    console.log("error catch : " + error);

    if (error.toString().includes("user rejected transaction")) {
      console.log("AA");
      return { msg: "User rejected transaction", status: "fail" };
    }

    if (error?.data?.message) {
      if (error?.data?.message.includes("err: insufficient funds for gas")) {
        console.log("A");
        return { msg: "Insufficient ETH Amount", status: "fail" };
      } else {
        console.log("B");
        return { msg: error?.data?.message, status: "fail" };
      }
    }
    let errorMessage = await getContractError(error);
    if (errorMessage) {
      console.log("C");
      return { msg: errorMessage, status: "fail" };
    } else {
      return { msg: "Somethigns went wrong", status: "fail" };
    }
  }
}
