import { Card, Container, Row } from "reactstrap";
import Header from "components/Headers/Header.js";
import FullBuyTransaction from "variables/FullBuyTransaction";

const Transactions = () => {
  return (
    <>
      <Header />
      <FullBuyTransaction />
    </>
  );
};

export default Transactions;
