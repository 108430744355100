import Swal from "sweetalert2";
import { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
} from "reactstrap";
import { adminLoginAPI } from "services/api";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "reduxToolkit/slice/auth";
import logoBlack from "../../assets/img/brand/logo-black.svg";
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [token, setToken] = useState(null);
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setFormErrors({});

    // Reset errors
    const errors = {};

    if (!email) {
      errors.email = "Email cannot be empty";
    }

    if (!password) {
      errors.password = "Password cannot be empty";
    }

    // If there are any validation errors, set them and return
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    try {
      // Call the API to authenticate user
      const output = await adminLoginAPI(email, password);
      console.log("output of adminLoginAPI : ", output);

      setToken(output?.token);
      if (output?.token) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Logged in Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        localStorage.setItem("accessToken", output.token);

        console.log("output.admin just before login : ", output.admin);

        // Dispatch login action with admin data
        dispatch(login({ admin: output.admin }));

        navigation("/admin/index");
      } else {
        console.log("Invalid Access Token");
      }
    } catch (error) {}
  };

  return (
    <Col lg="5" md="7">
      <Card className="bg-secondary shadow border-0">
        <CardHeader className="bg-transparent">
          <div className="btn-wrapper text-center">
            <img alt="Logo" src={logoBlack} width={250} height={100} />
          </div>
        </CardHeader>
        <CardBody className="px-lg-5 py-lg-5">
          <div className="text-center text-muted mb-4">
            <small>Sign in with credentials</small>
          </div>
          <Form role="form">
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Email"
                  type="email"
                  autoComplete="new-email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </InputGroup>
              {formErrors.email && (
                <div className="text-danger">{formErrors.email}</div>
              )}
            </FormGroup>
            <FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-lock-circle-open" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Password"
                  type="password"
                  autoComplete="new-password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </InputGroup>
              {formErrors.password && (
                <div className="text-danger">{formErrors.password}</div>
              )}
            </FormGroup>
            <div className="custom-control custom-control-alternative custom-checkbox">
              <input
                className="custom-control-input"
                id="customCheckLogin"
                type="checkbox"
              />
            </div>
            <div className="text-center">
              <Button
                className="my-4"
                color="primary"
                type="button"
                onClick={handleSubmit}
              >
                Sign in
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default Login;
