import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  admin: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    login: (state, action) => {
      console.log("Login action payload:", action.payload);
      state.admin = action.payload.admin;
    },
    logout: () => initialState,
  },
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
