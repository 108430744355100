import { useEffect, useState } from "react";
import Chart from "chart.js";
import { Nav, Container, Row, Col } from "reactstrap";
import { chartOptions, parseOptions, chartExample1 } from "variables/charts.js";
import Header from "components/Headers/Header.js";
import RecentStake from "variables/RecentStake";
import RecentBuy from "variables/RecentBuy";
import { useDispatch, useSelector } from "react-redux";
import { fetchAdminProfile } from "services/api";
import { login } from "reduxToolkit/slice/auth";
import { useNavigate } from "react-router-dom";
import StakeChart from "./modal/StakeChart";
import SalesChart from "./modal/SalesChart";

const Index = (props) => {
  const [chartExample1Data, setChartExample1Data] = useState("data1");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function getAdminProfile() {
    try {
      let output = await fetchAdminProfile();
      console.log(output, "admin 98");
      // dispatch(login({ admin: output?.data }));

      console.log("output.admin 2  after login : ", output?.data?.admin);

      // Dispatch login action with admin data
      dispatch(login({ admin: output.admin }));
    } catch (error) {
      console.error("Error fetching admin profile:", error);
    }
  }

  useEffect(() => {
    getAdminProfile();
  }, [navigate]);

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <SalesChart chartExample1Data={chartExample1Data} />
          <StakeChart />
        </Row>
        <RecentBuy />
        <RecentStake />
      </Container>
    </>
  );
};

export default Index;
