import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

export const trimAddress = (address) => {
  if (!address || typeof address !== "string") {
    return "";
  }

  if (address.length <= 15) {
    return address;
  } else {
    return address.slice(0, 10) + "..." + address.slice(-5);
  }
};

export const trimContent = (text, length) => {
  if (!text || typeof text !== "string") {
    return "";
  }

  if (text.length <= length) {
    return text;
  } else {
    return text.slice(0, length) + "...";
  }
};

export const formatNumber = (input, decimalPlace) => {
  if (!input) {
    return;
  }
  let str = input.toString();
  let dotIndex = str.indexOf(".");
  if (dotIndex === -1) {
    return str;
  }
  let preDot = str.substring(0, dotIndex + 1);
  let postDot = str.substring(dotIndex + 1, dotIndex + decimalPlace + 1);
  return preDot + postDot;
};

export const formatDate = (dateString) => {
  // Convert the date string to a Date object
  const date = new Date(dateString);

  // Array of month names
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Get day, month, year, hours, and minutes
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Format the date in "DD-Mon-YYYY hh:mmAM/PM" format
  const formattedDate = `${day}-${monthNames[monthIndex]}-${year} ${
    hours % 12 || 12
  }:${minutes < 10 ? "0" + minutes : minutes}${hours >= 12 ? " PM" : " AM"}`;

  return formattedDate;
};

export const swalFire = async (icon, title) => {
  Swal.fire({
    position: "center",
    icon: icon,
    title: title,
    showConfirmButton: false,
    timer: 1500,
  });
};

// // Function to handle CSV download
export const handleCSVDownload = (filteredUsers, desiredFields, fileName) => {
  // Map filteredUsers to include only the desired fields
  const filteredData = filteredUsers.map((user) => {
    let filteredUser = {};
    desiredFields.forEach((field) => {
      filteredUser[field] = user[field];
    });
    return filteredUser;
  });

  // Extracting keys from the first object in filteredUsers
  const titleKeys = Object.keys(filteredData[0]);

  // Creating CSV content
  const csvContent =
    titleKeys.join(",") +
    "\n" +
    filteredUsers
      .map((row) => titleKeys.map((key) => row[key]).join(","))
      .join("\n");

  // Creating Blob and URL
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
  const objUrl = URL.createObjectURL(blob);

  // Creating download link
  const link = document.createElement("a");
  link.setAttribute("href", objUrl);
  link.setAttribute("download", fileName);
  link.textContent = "Click to Download";

  // Appending link to body and triggering download
  document.body.appendChild(link);
  link.click();
};

// Function to handle Excel download
export const handleExcelDownload = (filteredUsers, desiredFields, fileName) => {
  // console.log("filteredUsers : ", filteredUsers);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  // Map filteredUsers to include only the desired fields
  const filteredData = filteredUsers.map((user) => {
    let filteredUser = {};
    desiredFields.forEach((field) => {
      filteredUser[field] = user[field];
    });
    return filteredUser;
  });

  const ws = XLSX.utils.json_to_sheet(filteredData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  // const fileName = "users" + fileExtension;
  saveAs(data, fileName); // Use saveAs function from file-saver library
};

export const cleanHTMLTag = (text) => {
  // Remove <p> and </p> tags
  let cleanedText = text.replace(/<\/?p>/g, "");
  // Remove leading spaces
  cleanedText = cleanedText.replace(/^\s+/, "");
  return cleanedText;
};

export const remove_p_br_span_Tags = (text) => {
  // Remove <p>, </p>, <br>, <li>, and <span> tags
  let result = text
    .replace(/<\/?p>/gi, "")
    .replace(/<br\s*\/?>/gi, "")
    .replace(/<li\s*\/?>/gi, "")
    .replace(/<\/?span[^>]*>/gi, "");

  return result;
};

export const secondToMonth = (seconds) => {
  const months = Math.floor(seconds / (3600 * 24 * 30));
  return months;
};
export const monthsToSeconds = (months) => {
  const seconds = months * 3600 * 24 * 30;
  return seconds;
};

export const getContractError = async (errorMessage) => {
  const regex = /(?<=\(reason="execution reverted: )(.*?)(?=",)/;
  const match = errorMessage.toString().match(regex);
  if (match) {
    const specificData = match[0];
    console.log("specificData : ", specificData);
    return specificData;
  } else {
    return null;
  }
};

export const formatDOB = (dateString) => {
  // Convert the date string to a Date object
  const date = new Date(dateString);

  // Array of month names
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Get day, month, year, hours, and minutes
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  // Format the date in "DD-Mon-YYYY hh:mmAM/PM" format
  const formattedDate = `${day}-${monthNames[monthIndex]}-${year} `;

  return formattedDate;
};
