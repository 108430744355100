import axios from "axios";
import Swal from "sweetalert2";
import { swalFire } from "./help";

const API = process.env.REACT_APP_API_URL;

const logout = async () => {
  localStorage.removeItem("accessToken");
};

// Admin login via .env
// export const adminLoginAPI = async (email, password) => {
//   try {
//     // const endPoint = process.env.REACT_APP_API_URL + "/api/v1/auth/admin-login";
//     const endPoint = `${API}/api/v1/auth/admin-login`;

//     let data = {
//       email: email,
//       password: password,
//     };

//     console.log("endPoint : ", endPoint);
//     const respnse = await axios.post(endPoint, data);

//     return respnse.data;
//   } catch (e) {
//     console.error("Error:", e?.response?.data?.errors[0]?.msg);
//     Swal.fire({
//       position: "center",
//       icon: "error",
//       title: e?.response?.data?.errors[0]?.msg,
//       showConfirmButton: false,
//       timer: 1500,
//     });
//     // alert(e?.response?.data?.errors[0]?.msg);
//     return {
//       token: "",
//     };
//   }
// };

export const adminLoginAPI = async (email, password) => {
  try {
    const endPoint = `${API}/api/v1/auth/admin-login2`;

    let data = {
      email: email,
      password: password,
    };

    console.log("endPoint : ", endPoint);
    const response = await axios.post(endPoint, data);

    console.log("Admin login : ", response);

    return response?.data;
  } catch (e) {
    console.error("Error:", e?.response?.data?.errors[0]?.msg);
    Swal.fire({
      position: "center",
      icon: "error",
      title: e?.response?.data?.errors[0]?.msg,
      showConfirmButton: false,
      timer: 1500,
    });
  }
};

export const getAllBuyTx = async (data) => {
  try {
    const endPoint = `${API}/api/v1/auth/all-buy-tx`;
    const token = localStorage.getItem("accessToken");

    const respnse = await axios.post(endPoint, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    return {
      data: respnse.data,
      token: token,
    };
  } catch (e) {
    if (e?.response?.status === 401) {
      await logout();
      return {
        token: "",
      };
    }
    console.error("Error from getAllBuyTx:", e?.response?.data?.errors[0]?.msg);
    Swal.fire({
      position: "center",
      icon: "error",
      title: e?.response?.data?.errors[0]?.msg,
      showConfirmButton: false,
      timer: 1500,
    });

    return {
      token: "",
    };
  }
};

export const getAllUsers = async (data) => {
  try {
    const endPoint = `${API}/api/v1/auth/users`;
    const token = localStorage.getItem("accessToken");
    console.log("endPoint : ", endPoint);
    const respnse = await axios.post(endPoint, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    return respnse.data;
  } catch (e) {
    if (e?.response?.status === 401) {
      await logout();
      return {
        token: "",
      };
    }
    console.error("Error:", e?.response?.data?.errors[0]?.msg);
    Swal.fire({
      position: "center",
      icon: "error",
      title: e?.response?.data?.errors[0]?.msg,
      showConfirmButton: false,
      timer: 1500,
    });

    return {
      token: "",
    };
  }
};

export const getAllStakeTx = async (data) => {
  try {
    const endPoint = `${API}/api/v1/auth/all-stake-tx`;
    const token = localStorage.getItem("accessToken");

    console.log("endPoint : ", endPoint);
    const respnse = await axios.post(endPoint, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    return respnse.data;
  } catch (e) {
    console.error("Error:", e?.response?.data?.errors[0]?.msg);
  }
};

export const getHeaderData = async (data) => {
  try {
    const endPoint = `${API}/api/v1/auth/get-header`;
    const token = localStorage.getItem("accessToken");

    const respnse = await axios.get(endPoint, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    return respnse?.data;
  } catch (e) {
    if (e?.response?.status === 401) {
      await logout();
      return {
        token: "",
      };
    }
    console.error("Error:", e?.response?.data?.errors[0]?.msg);
    Swal.fire({
      position: "center",
      icon: "error",
      title: e?.response?.data?.errors[0]?.msg,
      showConfirmButton: false,
      timer: 1500,
    });

    return {
      token: "",
    };
  }
};

// http://localhost:8000/api/v1/auth/admin
export const fetchAdminProfile = async () => {
  try {
    const endPoint = `${API}/api/v1/auth/admin`;
    const token = localStorage.getItem("accessToken");

    const response = await axios.get(endPoint, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      await logout();
      return {
        token: "",
      };
    }
    console.error("Error:", e?.response?.data?.errors[0]?.msg);
    Swal.fire({
      position: "center",
      icon: "error",
      title: e?.response?.data?.errors[0]?.msg,
      showConfirmButton: false,
      timer: 1500,
    });

    return {
      token: "",
    };
  }
};

// localhost:8000/api/v1/auth/delete-user
export const deleteUser = async (userId) => {
  try {
    const endPoint = `${API}/api/v1/auth/delete-user`;
    const token = localStorage.getItem("accessToken");
    console.log("Token : ", token);
    if (!token) {
      swalFire("error", "Admin not login");
    }

    const response = await axios.delete(endPoint, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        userId: userId, // Pass the user ID to delete
      },
    });

    console.log("response : ", response);

    return response?.data;
  } catch (e) {
    console.log("Er : ", e);
    if (e?.response?.status === 401) {
      await logout();
      return {
        token: "",
      };
    }
    console.error("Error:", e?.response?.data?.errors[0]?.msg);
    swalFire("error", e?.response?.data?.errors[0]?.msg);

    return {
      token: "",
    };
  }
};
// localhost:8000/api/v1/auth/update-user-by-admin
export const updateUserByAdmin = async (userData) => {
  try {
    const endPoint = `${API}/api/v1/auth/update-user-by-admin`;
    const token = localStorage.getItem("accessToken");
    if (!token) {
      swalFire("error", "Admin not logged in");
    }

    const response = await axios.patch(endPoint, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    return response?.data;
  } catch (e) {
    console.log("Error:", e);
    if (e?.response?.status === 401) {
      await logout();
      return { token: "" };
    }
    console.error("Error:", e?.response?.data?.errors[0]?.msg);
    swalFire("error", e?.response?.data?.errors[0]?.msg);

    return { token: "" };
  }
};

// http://localhost:8000/api/v1/auth/admin/update
export const updateAdminProfile = async (userData) => {
  try {
    console.log("userData : ", userData);
    const endPoint = `${API}/api/v1/auth/admin/update`;
    const token = localStorage.getItem("accessToken");
    if (!token) {
      swalFire("error", "Admin not logged in");
    }

    // let data = {
    //   currentPassword: userData.currentPassword,
    //   newPassword: userData.newPassword,
    //   confirmPassword: userData.confirmPassword,
    // };

    const response = await axios.patch(endPoint, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    console.log("response from change pass : " + response);
    // if (response?.error) {
    //   swalFire("error", response?.error);
    //   return;
    // }

    return response?.data;
  } catch (e) {
    console.log("Error:", e);
    if (e?.response?.status === 401) {
      await logout();
      return { token: "" };
    }
    console.error("Error 88:", e?.response?.data?.error);
    swalFire("error", e?.response?.data?.error);

    return { token: "" };
  }
};

export const updateAdminPassword = async (userData) => {
  try {
    console.log("userData : ", userData);
    const endPoint = `${API}/api/v1/auth/admin/update-password`;
    const token = localStorage.getItem("accessToken");
    if (!token) {
      swalFire("error", "Admin not logged in");
    }

    let data = {
      currentPassword: userData.currentPassword,
      newPassword: userData.newPassword,
      confirmPassword: userData.confirmPassword,
    };

    const response = await axios.patch(endPoint, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    console.log("response from change pass : " + response);
    // if (response?.error) {
    //   swalFire("error", response?.error);
    //   return;
    // }

    return response?.data;
  } catch (e) {
    console.log("Error:", e);
    if (e?.response?.status === 401) {
      await logout();
      return { token: "" };
    }
    console.error("Error 88:", e?.response?.data?.error);
    swalFire("error", e?.response?.data?.error);

    return { token: "" };
  }
};

export const getAllEmails = async (_id, content, status) => {
  try {
    const endPoint = `${API}/api/v1/auth/get-emails`;
    // const endPoint = `${API}/api/v1/auth/get-emails?_id=${_id}&content=${content}&status=${status}`;
    const token = localStorage.getItem("accessToken");
    console.log("endPoint : ", endPoint);
    const respnse = await axios.get(endPoint, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    return respnse.data;
  } catch (e) {
    if (e?.response?.status === 401) {
      await logout();
      return {
        token: "",
      };
    }
    console.error("Error:", e?.response?.data?.errors[0]?.msg);
    Swal.fire({
      position: "center",
      icon: "error",
      title: e?.response?.data?.errors[0]?.msg,
      showConfirmButton: false,
      timer: 1500,
    });

    return {
      token: "",
    };
  }
};

export const updateEmail = async (data) => {
  try {
    const endPoint = `${API}/api/v1/auth/update-email`;
    const token = localStorage.getItem("accessToken");
    if (!token) {
      swalFire("error", "Admin not logged in");
    }

    const response = await axios.patch(endPoint, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    console.log("response : ", response);

    return response?.data;
  } catch (e) {
    console.log("Error:", e);
    if (e?.response?.status === 401) {
      await logout();
      return { token: "" };
    }
    console.error("Error:", e?.response?.data?.errors[0]?.msg);
    swalFire("error", e?.response?.data?.errors[0]?.msg);

    return { token: "" };
  }
};

export const getAllFaq = async () => {
  try {
    const endPoint = `${API}/api/v1/get-all-faq`;
    console.log("endPoint : ", endPoint);
    const respnse = await axios.get(endPoint);

    console.log("get faq api res : ", respnse?.data?.faq);

    return respnse?.data?.faq;
  } catch (e) {
    if (e?.response?.status === 401) {
      await logout();
      return {
        token: "",
      };
    }
    console.error("Error:", e?.response?.data?.errors[0]?.msg);
    Swal.fire({
      position: "center",
      icon: "error",
      title: e?.response?.data?.errors[0]?.msg,
      showConfirmButton: false,
      timer: 1500,
    });

    return {
      token: "",
    };
  }
};

export const updateFaq = async (data) => {
  try {
    const endPoint = `${API}/api/v1/auth/update-faq`;
    const token = localStorage.getItem("accessToken");
    if (!token) {
      swalFire("error", "Admin not logged in");
    }

    const response = await axios.patch(endPoint, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    return response?.data;
  } catch (e) {
    console.log("Error:", e);
    if (e?.response?.status === 401) {
      await logout();
      return { token: "" };
    }
    console.error("Error:", e?.response?.data?.errors[0]?.msg);
    swalFire("error", e?.response?.data?.errors[0]?.msg);

    return { token: "" };
  }
};

export const deleteFaq = async (_id) => {
  try {
    const endPoint = `${API}/api/v1/auth/delete-faq`;
    const token = localStorage.getItem("accessToken");
    console.log("Token : ", token);
    if (!token) {
      swalFire("error", "Admin not login");
    }

    const response = await axios.delete(endPoint, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        _id,
      },
    });

    console.log("response : ", response);

    return response?.faq;
  } catch (e) {
    console.log("Er : ", e);
    if (e?.response?.status === 401) {
      await logout();
      return {
        token: "",
      };
    }
    console.error("Error:", e?.response?.data?.errors[0]?.msg);
    swalFire("error", e?.response?.data?.errors[0]?.msg);

    return {
      token: "",
    };
  }
};

export const createFaq = async (data) => {
  try {
    const endPoint = `${API}/api/v1/auth/create-faq`;
    const token = localStorage.getItem("accessToken");
    console.log("Token : ", token);
    if (!token) {
      swalFire("error", "Admin not login");
    }

    const response = await axios.post(endPoint, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    console.log("response : ", response);

    return response?.data;
  } catch (e) {
    console.log("Er : ", e);
    if (e?.response?.status === 401) {
      await logout();
      return {
        token: "",
      };
    }
    console.error("Error:", e?.response?.data?.errors[0]?.msg);
    swalFire("error", e?.response?.data?.errors[0]?.msg);

    return {
      token: "",
    };
  }
};

// http://localhost:8000/api/v1/auth/admin/update-dp
export const updateAdminDp = async (data) => {
  try {
    const token = localStorage.getItem("accessToken");

    const formData = new FormData();

    formData.append("keyDoc", data); // Append the file

    console.log("formData : ", formData, typeof formData);

    // const endPoint = process.env.REACT_APP_API_URL + "/api/v1/auth/admin-login";
    const endPoint = `${API}/api/v1/auth/admin/update-dp`;

    console.log("endPoint : ", endPoint);
    console.log("Data to api : ", formData);

    const response = await axios.post(endPoint, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });

    console.log("response : ", response?.data);

    return response?.data;
  } catch (e) {
    console.log("EEEEE : ", e);
    console.error("Error:", e?.response?.data?.errors[0]?.msg);
  }
};

export const getAllEnquiry = async (data) => {
  const endPoint = `${API}/api/v1/enquiry/list`;
  console.log("getAll Enquiery : ", endPoint);
  // http://localhost:8000/api/v1/enquiry/list
  const response = await axios.post(endPoint, data);
  console.log("response of get all entry : ", response);
  return response?.data;
};

export const ReplyToInquiry = async (data) => {
  try {
    const token = localStorage.getItem("accessToken");

    // const endPoint = http://localhost:8000/api/v1/auth/admin/reply-enquiry
    const endPoint = `${API}/api/v1/auth/admin/reply-enquiry`;

    const response = await axios.post(endPoint, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    console.log("response : ", response?.data);

    return response?.data;
  } catch (e) {
    console.log("EEEEE : ", e);
    console.error("Error:", e?.response?.data?.errors[0]?.msg);
  }
};

export const getAllContent = async (_id) => {
  try {
    const endPoint = `${API}/api/v1/get-content`;

    console.log("endPoint : ", endPoint);
    const respnse = await axios.get(endPoint);

    return respnse.data;
  } catch (e) {
    if (e?.response?.status === 401) {
      await logout();
      return {
        token: "",
      };
    }
    console.error("Error:", e?.response?.data?.errors[0]?.msg);
    Swal.fire({
      position: "center",
      icon: "error",
      title: e?.response?.data?.errors[0]?.msg,
      showConfirmButton: false,
      timer: 1500,
    });

    return {
      token: "",
    };
  }
};

export const updateContent = async (data) => {
  try {
    const endPoint = `${API}/api/v1/auth/update-content`;
    const token = localStorage.getItem("accessToken");
    if (!token) {
      swalFire("error", "Admin not logged in");
    }

    const response = await axios.patch(endPoint, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    console.log("response : ", response);

    return response?.data;
  } catch (e) {
    console.log("Error:", e);
    if (e?.response?.status === 401) {
      await logout();
      return { token: "" };
    }
    console.error("Error:", e?.response?.data?.errors[0]?.msg);
    swalFire("error", e?.response?.data?.errors[0]?.msg);

    return { token: "" };
  }
};

export const getSaleChartData = async () => {
  try {
    const endPoint = `${API}/api/v1/get-sale-chart-data`;
    const response = await axios.get(endPoint);

    console.log("response : ", response);

    return response?.data;
  } catch (e) {
    console.log(("Error in getSaleChartData : ", e));
  }
};

export const getStakeChartData = async () => {
  try {
    const endPoint = `${API}/api/v1/get-stake-chart-data`;
    const response = await axios.get(endPoint);

    console.log("response : ", response);

    return response?.data;
  } catch (e) {
    console.log(("Error in getSaleChartData : ", e));
  }
};

export const resetCronTimeApi = async (data) => {
  try {
    const endPoint = `${API}/api/v1/auth/update-cron`;
    const token = localStorage.getItem("accessToken");
    if (!token) {
      swalFire("error", "Admin not logged in");
    }

    const response = await axios.patch(endPoint, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    console.log("response : ", response);

    return response?.data;
  } catch (e) {
    console.log("Error:", e);
    if (e?.response?.status === 401) {
      await logout();
      return { token: "" };
    }
    console.error("Error:", e?.response?.data?.errors[0]?.msg);
    swalFire("error", e?.response?.data?.errors[0]?.msg);

    return { token: "" };
  }
};
