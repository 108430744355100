import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  CardHeader,
  CardTitle,
  Table,
} from "reactstrap";

import { useEffect, useState } from "react";
import { swalFire } from "services/help";
import Header from "components/Headers/Header";
import { formatNumber } from "services/help";
import { ethers } from "ethers";
import { Loader } from "variables/Loader";
import {
  latestPrice,
  manuallyRebalance,
  getStablecoinDashboard,
  depositETHSend,
  mintTokenSend,
  getRebalanceHistory,
  withdrawETHSend,
} from "services/contractHelper";
import { formatDate } from "services/help";
import { resetCronTimeApi } from "services/api";
import { fetchAdminProfile } from "services/api";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import axios from "axios";
import { withdrawCUCCFromGovSend } from "services/contractHelper";

const Stability = () => {
  const { isConnected } = useWeb3ModalAccount();
  const [read, setRead] = useState({
    totalSupply: 0,
    supplyChangeCount: 0,
    price: 0,
    amount: 0,
    governenceCUCC: 0,
  });

  const [rebalanceHist, setRebalanceHist] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ethDepositInput, setEthDepositInput] = useState("");
  const [ethWithdrawInput, setEthWithdrawInput] = useState("");
  const [mintInput, setMintInput] = useState("");
  const [cronTime, setCronTime] = useState("");
  const [admin, setAdmin] = useState(null);
  const [coperPrice, setCopperPrice] = useState(0);
  const [govTokenWithdraw, setGovTokenWithdraw] = useState(0);

  async function getAdminProfile() {
    try {
      let output = await fetchAdminProfile();

      console.log("output.admin 2  after login : ", output?.data?.admin);
      setAdmin(output?.data?.admin);
    } catch (error) {
      console.error("Error fetching admin profile:", error);
    }
  }
  async function getCopperPrice() {
    try {
      const api = "https://coppercapi.copperc20.com/api/v1/get-copper-price";
      const result = await axios.get(api);
      console.log("Api call result: ", result?.data?.price);
      if (result?.data?.price) {
        setCopperPrice(result?.data?.price);
      }
    } catch (e) {
      console.log("error in copper price fetch : ");
    }
  }
  useEffect(() => {
    try {
      getCopperPrice();
    } catch (error) {
      console.log("Error in getCopperPrice(); : ", error);
    }
  }, []);

  useEffect(() => {
    console.log("admin admin admin : ", admin);

    getAdminProfile();
  }, [cronTime]);

  async function getData() {
    setLoading(true);
    try {
      let output = await getStablecoinDashboard();
      setRead(output);
      let rebalanceHist = await getRebalanceHistory();
      console.log("rebalanceHist : ", rebalanceHist);
      setRebalanceHist(rebalanceHist);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching admin profile:", error);
      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  const depositToken = async () => {
    if (!isConnected) {
      swalFire("error", "Please connect to the metamask wallet");
      return;
    }

    if (!ethDepositInput > 0) {
      swalFire("error", "Please enter a valid deposit amount");

      return;
    }
    setLoading(true);
    try {
      if (!window.ethereum) {
        window.open(
          `https://metamask.app.link/dapp/${window.location.hostname}/`
        );
      }

      let signer = null;

      await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      signer = provider.getSigner();

      const transaction = await depositETHSend(signer, ethDepositInput);
      if (transaction.status === "success") {
        swalFire("success", "Successfully Deposit ETH");
      } else {
        swalFire("error", transaction?.msg);
      }
    } catch (error) {
      console.error("Transaction failed:", error);
    }

    setEthDepositInput("");
    setLoading(false);
  };

  const withdrawToken = async () => {
    if (!isConnected) {
      swalFire("error", "Please connect to the metamask wallet");
      return;
    }

    if (!ethWithdrawInput > 0) {
      swalFire("error", "Please enter a valid withdraw amount");
      return;
    }
    setLoading(true);
    try {
      if (!window.ethereum) {
        window.open(
          `https://metamask.app.link/dapp/${window.location.hostname}/`
        );
      }

      let signer = null;

      await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      signer = provider.getSigner();

      const transaction = await withdrawETHSend(signer, ethWithdrawInput);
      if (transaction.status === "success") {
        swalFire("success", "Successfully Withdraw Ethereum");
      } else {
        swalFire("error", transaction?.msg);
      }
    } catch (error) {
      console.error("Transaction failed:", error);
    }

    setEthWithdrawInput("");
    setLoading(false);
  };

  const mintToken = async () => {
    if (!isConnected) {
      swalFire("error", "Please connect to the metamask wallet");
      return;
    }

    if (!mintInput > 0) {
      swalFire("error", "Please enter a valid token amount");
      return;
    }
    setLoading(true);
    try {
      if (!window.ethereum) {
        window.open(
          `https://metamask.app.link/dapp/${window.location.hostname}/`
        );
      }

      let signer = null;

      await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      signer = provider.getSigner();

      const transaction = await mintTokenSend(signer, mintInput);
      if (transaction.status === "success") {
        swalFire("success", "Successfully Minted Tokens");
      } else {
        swalFire("error", transaction?.msg);
      }
    } catch (error) {
      console.error("Transaction failed:", error);
    }

    setMintInput("");
    setLoading(false);
  };

  const withdrawTokenFromGov = async () => {
    if (!isConnected) {
      swalFire("error", "Please connect to the metamask wallet");
      return;
    }

    if (!govTokenWithdraw > 0) {
      swalFire("error", "Please enter a valid withdraw amount");
      return;
    }
    setLoading(true);
    try {
      if (!window.ethereum) {
        window.open(
          `https://metamask.app.link/dapp/${window.location.hostname}/`
        );
      }

      let signer = null;

      await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      signer = provider.getSigner();

      const transaction = await withdrawCUCCFromGovSend(
        signer,
        govTokenWithdraw
      );
      if (transaction.status === "success") {
        swalFire("success", "Successfully Withdraw Token");
      } else {
        swalFire("error", transaction?.msg);
      }
    } catch (error) {
      console.error("Transaction failed:", error);
    }

    setGovTokenWithdraw("");
    setLoading(false);
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <Card className="card-profile shadow">
              <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    Set cron for rebalanced automatically
                  </h6>

                  <Row style={{ display: "flex", justifyContent: "center" }}>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="newPassword"
                        >
                          Time in hours
                        </label>
                        <Input
                          className="form-control-alternative"
                          type="text"
                          name="minutes"
                          placeholder="In hours"
                          value={cronTime}
                          onChange={(e) => {
                            const { value } = e.target;
                            if (/^\d+$/.test(value) || value === "") {
                              setCronTime(value);
                            }
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Button
                    color="info"
                    href="#pablo"
                    onClick={async () => {
                      if (!cronTime > 0) {
                        swalFire("error", "Invalid cron time ");
                        return;
                      }
                      const result = await resetCronTimeApi({
                        time: cronTime,
                      });
                      setCronTime("");
                      if (result?.admin) {
                        swalFire("success", result?.message);
                      } else {
                        console.log("Error in resetCronTimeApi : ", result);
                        swalFire(result?.error);
                      }
                      console.log("resetCronTimeApi called result : ", result);
                    }}
                  >
                    Reset Crom Time
                  </Button>
                </Form>
              </CardHeader>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <h6 className="heading-small text-muted mb-4">
                  {admin?.cronTime > 0
                    ? `A Cron will automatically run in each ${admin?.cronTime} hours.`
                    : "Cron is disabled"}
                </h6>
              </div>
            </Card>

            <Card className="card-profile shadow">
              <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    Fetch Lastest price for ETH and Copper
                  </h6>

                  <Row style={{ display: "flex", justifyContent: "center" }}>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="newPassword"
                        >
                          Latest price for Copper
                        </label>

                        <Input
                          className="form-control-alternative"
                          type="text"
                          name="link-amount"
                          placeholder="copper price"
                          value={coperPrice}
                          onChange={(e) => {
                            const { value } = e.target;
                            if (/^\d+$/.test(value) || value === "") {
                              setCopperPrice(value);
                            }
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Button
                    color="info"
                    onClick={async () => {
                      try {
                        if (!isConnected) {
                          swalFire(
                            "error",
                            "Please connect to the metamask wallet"
                          );
                          return;
                        }
                        if (!window.ethereum) {
                          window.open(
                            `https://metamask.app.link/dapp/${window.location.hostname}/`
                          );
                        }

                        let signer = null;
                        try {
                          await window.ethereum.request({
                            method: "eth_requestAccounts",
                          });
                          const provider = new ethers.providers.Web3Provider(
                            window.ethereum
                          );
                          signer = provider.getSigner();
                        } catch (e) {
                          console.log("Error in wallet connecting : ", e);
                        }

                        const transaction = await latestPrice(
                          signer,
                          await ethers.utils.parseEther(coperPrice.toString())
                        );
                        if (transaction.status === "success") {
                          swalFire(
                            "success",
                            "Successfully requested for latest price"
                          );
                        } else {
                          swalFire("error", transaction?.msg);
                        }
                      } catch (error) {
                        console.error("Transaction failed:", error);
                      }
                    }}
                  >
                    Set Price
                  </Button>
                  <Button
                    className="icon icon-shape bg-info text-white rounded-circle shadow"
                    onClick={async (e) => {
                      e.preventDefault();
                      await getCopperPrice();
                    }}
                  >
                    <i className="fas fa-rotate" />
                  </Button>
                </Form>
              </CardHeader>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              ></div>
            </Card>
          </Col>

          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
              <CardBody>
                <div
                  style={{
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                >
                  <Row
                    style={{
                      margin: 20,
                    }}
                  >
                    {/* Copper Total Supply  */}
                    <Col
                      lg="6"
                      xl="3"
                      style={{
                        marginBottom: 20,
                      }}
                    >
                      <Card className="card-stats mb-4 mb-xl-0">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                CUCC Total Supply
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0">
                                {formatNumber(
                                  Number(read?.totalSupply) / 10 ** 18,
                                  3
                                ) ?? 0}{" "}
                                CUCC
                              </span>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>

                    {/* Rebalance Count  */}
                    <Col
                      lg="6"
                      xl="3"
                      style={{
                        marginBottom: 20,
                      }}
                    >
                      <Card className="card-stats mb-4 mb-xl-0">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                Rebalance Count
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0">
                                {Number(read?.supplyChangeCount) ?? 0} Times
                              </span>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>

                    {/* Live Copper Price  */}
                    <Col
                      lg="6"
                      xl="3"
                      style={{
                        marginBottom: 20,
                      }}
                    >
                      <Card className="card-stats mb-4 mb-xl-0">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                Live Copper Price
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0">
                                ${" "}
                                {formatNumber(
                                  Number(read?.price.CopperUSD) / 10 ** 18,
                                  2
                                ) ?? 0}{" "}
                              </span>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>

                    {/* Live ETH Price  */}
                    <Col
                      lg="6"
                      xl="3"
                      style={{
                        marginBottom: 20,
                      }}
                    >
                      <Card className="card-stats mb-4 mb-xl-0">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                Live ETH Price
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0">
                                ${" "}
                                {formatNumber(
                                  Number(read?.price.ETHUSD) / 10 ** 18,
                                  2
                                ) ?? 0}{" "}
                              </span>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>

                    {/* CUCC For Reserve ETH  */}
                    <Col
                      lg="6"
                      xl="3"
                      style={{
                        marginBottom: 20,
                      }}
                    >
                      <Card className="card-stats mb-4 mb-xl-0">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                CUCC For Reserve ETH
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0">
                                {formatNumber(
                                  Number(read?.amount.Copper) / 10 ** 18,
                                  2
                                ) ?? 0}{" "}
                                CUCC
                              </span>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    {/* ETH Reserve Amount  */}
                    <Col
                      lg="6"
                      xl="3"
                      style={{
                        marginBottom: 20,
                      }}
                    >
                      <Card className="card-stats mb-4 mb-xl-0">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                ETH Reserve Amount
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0">
                                {formatNumber(
                                  Number(read?.amount.ETH) / 10 ** 18,
                                  3
                                )}{" "}
                                ETH
                              </span>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col lg="6" xl="3">
                      <Card className="card-stats mb-4 mb-xl-0">
                        <Button
                          color="info"
                          className="mt-3"
                          onClick={async () => {
                            try {
                              if (!isConnected) {
                                swalFire(
                                  "error",
                                  "Please connect to the metamask wallet"
                                );
                                return;
                              }

                              alert(
                                "Before Rebalance token supply, please request for latest price"
                              );
                              if (!window.ethereum) {
                                window.open(
                                  `https://metamask.app.link/dapp/${window.location.hostname}/`
                                );
                              }

                              let signer = null;
                              try {
                                await window.ethereum.request({
                                  method: "eth_requestAccounts",
                                });
                                const provider =
                                  new ethers.providers.Web3Provider(
                                    window.ethereum
                                  );
                                signer = provider.getSigner();
                              } catch (e) {
                                console.log("Error in wallet connecting : ", e);
                              }

                              const transaction = await manuallyRebalance(
                                signer
                              );
                              if (transaction.status === "success") {
                                swalFire("success", "Successfully rebalanced");
                              } else {
                                swalFire("error", transaction?.msg);
                              }
                            } catch (error) {
                              console.error("Transaction failed:", error);
                            }
                          }}
                        >
                          Manual Rebalance
                        </Button>
                      </Card>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col style={{ marginLeft: 44 }}>
                      <Button
                        color="info"
                        className="mt-3"
                        onClick={async () => {
                          try {
                            if (!isConnected) {
                              swalFire(
                                "error",
                                "Please connect to the metamask wallet"
                              );
                              return;
                            }

                            alert(
                              "Before Rebalance token supply, please request for latest price"
                            );
                            if (!window.ethereum) {
                              window.open(
                                `https://metamask.app.link/dapp/${window.location.hostname}/`
                              );
                            }

                            let signer = null;
                            try {
                              await window.ethereum.request({
                                method: "eth_requestAccounts",
                              });
                              const provider =
                                new ethers.providers.Web3Provider(
                                  window.ethereum
                                );
                              signer = provider.getSigner();
                            } catch (e) {
                              console.log("Error in wallet connecting : ", e);
                            }

                            const transaction = await manuallyRebalance(signer);
                            if (transaction.status === "success") {
                              swalFire("success", "Successfully rebalanced");
                            } else {
                              swalFire("error", transaction?.msg);
                            }
                          } catch (error) {
                            console.error("Transaction failed:", error);
                          }
                        }}
                      >
                        Manual Rebalance
                      </Button>
                    </Col>
                    <Col style={{ marginLeft: 44 }}>
                      <Button
                        color="info"
                        className="mt-3"
                        onClick={async () => {
                          try {
                            alert(
                              "After the successful transaction, it will take 2 to 5 seconds to update the price, depending on the network."
                            );

                            if (!isConnected) {
                              swalFire(
                                "error",
                                "Please connect to the metamask wallet"
                              );
                              return;
                            }
                            if (!window.ethereum) {
                              window.open(
                                `https://metamask.app.link/dapp/${window.location.hostname}/`
                              );
                            }

                            let signer = null;
                            try {
                              await window.ethereum.request({
                                method: "eth_requestAccounts",
                              });
                              const provider =
                                new ethers.providers.Web3Provider(
                                  window.ethereum
                                );
                              signer = provider.getSigner();
                            } catch (e) {
                              console.log("Error in wallet connecting : ", e);
                            }

                            const transaction = await latestPrice(signer);
                            if (transaction.status === "success") {
                              swalFire(
                                "success",
                                "Successfully requested for latest price"
                              );
                            } else {
                              swalFire("error", transaction?.msg);
                            }
                          } catch (error) {
                            console.error("Transaction failed:", error);
                          }
                        }}
                      >
                        Request Latest Price
                      </Button>
                    </Col>

                    <Col style={{ marginLeft: 44 }}>
                      <Button
                        color="info"
                        className="mt-3"
                        onClick={async () => {
                          if (!isConnected) {
                            swalFire(
                              "error",
                              "Please connect to the metamask wallet"
                            );
                            return;
                          }

                          setLoading(true);
                          try {
                            if (!window.ethereum) {
                              window.open(
                                `https://metamask.app.link/dapp/${window.location.hostname}/`
                              );
                            }
                            let signer = null;

                            await window.ethereum.request({
                              method: "eth_requestAccounts",
                            });
                            const provider = new ethers.providers.Web3Provider(
                              window.ethereum
                            );
                            signer = provider.getSigner();

                            const transaction = await withdrawLinkSend(signer);
                            if (transaction.status === "success") {
                              swalFire(
                                "success",
                                "Successfully withdraw link token"
                              );
                            } else {
                              swalFire("error", transaction?.msg);
                            }
                          } catch (error) {
                            console.error("Transaction failed:", error);
                          }
                          setLoading(false);
                        }}
                      >
                        Withdraw Link Token
                      </Button>
                    </Col>
                  </Row> */}
                </div>
              </CardBody>
            </Card>

            {/* New Section for Minting Tokens */}
            <Card className="bg-secondary shadow mt-4">
              <CardBody>
                <Row>
                  {/* Deposit ETH For Reserve */}
                  <Col lg="4">
                    <h6 className="heading-small text-muted mb-4">
                      Deposit / Reserve ETH
                    </h6>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="mintAmount1"
                      >
                        Amount to reserve
                      </label>
                      <Input
                        className="form-control-alternative"
                        type="number"
                        name="depositAmount"
                        placeholder="Enter deposit amount"
                        value={ethDepositInput}
                        onChange={(e) => {
                          if (e.target.value >= 0) {
                            setEthDepositInput(e.target.value);
                          }
                        }}
                      />
                      <Button
                        color="info"
                        className="mt-3"
                        onClick={depositToken}
                      >
                        Reserve Amount
                      </Button>
                    </FormGroup>
                  </Col>

                  {/* Withdraw ETH From Reserve */}
                  <Col lg="4">
                    <h6 className="heading-small text-muted mb-4">
                      Withdraw Reserve ETH Amount
                    </h6>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="mintAmount2"
                      >
                        Amount to withdraw
                      </label>
                      <Input
                        className="form-control-alternative"
                        type="number"
                        name="withdrawAmount"
                        placeholder="Enter withdraw amount"
                        value={ethWithdrawInput}
                        onChange={(e) => {
                          if (e.target.value >= 0) {
                            setEthWithdrawInput(e.target.value);
                          }
                        }}
                      />
                      <Button
                        color="info"
                        className="mt-3"
                        onClick={withdrawToken}
                      >
                        Withdraw Amount
                      </Button>
                    </FormGroup>
                  </Col>

                  {/* Mint Token Section 3 */}
                  <Col lg="4">
                    <h6 className="heading-small text-muted mb-4">
                      Mint Tokens
                    </h6>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="mintAmount3"
                      >
                        Amount to Mint
                      </label>
                      <Input
                        className="form-control-alternative"
                        type="number"
                        name="mintAmount3"
                        placeholder="Enter amount"
                        value={mintInput}
                        onChange={(e) => setMintInput(e.target.value)}
                      />
                      <Button color="info" className="mt-3" onClick={mintToken}>
                        Mint Tokens
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="order-xl-1" xl="8">
            <Col>
              <Card className="bg-secondary shadow mt-4" xl="4">
                <CardBody>
                  <Row>
                    <h3 className="heading-small text-muted mb-4">
                      Rebalance history (last 10 transactions)
                    </h3>
                    <div className="table-responsive">
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">SN</th>
                            <th scope="col">Method</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Time</th>
                            <th scope="col">Block Number</th>
                          </tr>
                        </thead>
                        <tbody>
                          {rebalanceHist && rebalanceHist?.length > 0 ? (
                            rebalanceHist.map((item, index) => (
                              <tr key={index}>
                                <td>{item.sn}</td>
                                <td>{item.method}</td>
                                <td>{formatNumber(item.amount, 6)}</td>
                                <td>
                                  {formatDate(Number(item.timestamp * 1000))}
                                </td>
                                <td>{Number(item.blocknum)}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="7" className="text-center">
                                No data found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Col>
          {/* Withdraw from Governance  */}
          <Col className="order-xl-1" xl="4">
            <Card className="card-profile shadow">
              <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    Withdraw CUCC From Governance Contract
                  </h6>

                  <Row style={{ display: "flex", justifyContent: "center" }}>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="newPassword"
                        >
                          Available CUCC :{" "}
                          {formatNumber(read?.governenceCUCC, 3)}
                        </label>

                        <Input
                          className="form-control-alternative"
                          type="number"
                          name="cucc-amount"
                          placeholder="Withdraw amount"
                          value={govTokenWithdraw}
                          onChange={(e) => {
                            setGovTokenWithdraw(e.target.value);
                          }}
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Button color="info" onClick={withdrawTokenFromGov}>
                    Withdraw Token
                  </Button>
                </Form>
              </CardHeader>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              ></div>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Stability;
