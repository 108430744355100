import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import Header from "components/Headers/Header.js";
import { getAllEnquiry } from "services/api";
import { formatDate } from "services/help";
import { InquiryModel } from "views/modal/InquiryModel";
import { trimContent } from "services/help";

const Inquiry = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [filteredEnquiries, setFilteredEnquiries] = useState([]);
  const [enquiries, setEnquiries] = useState([]);
  const [selectedInquiry, setSelectedInquiry] = useState(null);
  const [initialDate, setInitialDate] = useState({ start: "", end: "" });
  const [filterDate, setFilterDate] = useState({ start: "", end: "" });
  const [status, setStatus] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let data = {
          status: status == true ? "true" : status === false ? "false" : "",
          page: pageNumber,
          limit: 5,
        };

        if (filterDate.start !== "" && filterDate.end !== "") {
          data.dateRange = filterDate;
        }

        // const output = await getAllEnquiry(data);

        console.log("filter data from enquire data : ", data);
        const output = await getAllEnquiry(data);

        console.log("output from getAllEnquiry: ", output);

        setEnquiries(output?.enquiry);

        console.log("output.docs for enquiry : ", output?.enquiry?.docs);

        // Filter users based on search term
        const filtered = output?.enquiry?.docs.filter(
          (user) =>
            user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.subject.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.message.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredEnquiries(filtered);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call the fetchData function
  }, [status, filterDate, pageNumber, searchTerm, loader]);

  const toggle = (inquiry) => {
    setSelectedInquiry(inquiry);
    setLoader((prev) => !prev);
    setIsOpen(!isOpen);
  };

  console.log("enquiries : ", enquiries);

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row">
                  <h3 className="mb-0">Website Inquiry Management</h3>

                  {/* Search bar */}
                  <InputGroup className="ml-auto" style={{ maxWidth: "400px" }}>
                    <Input
                      placeholder="Search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <InputGroupAddon addonType="append">
                      {/* <Button color="primary">Search</Button> */}

                      <Button color="primary">Search</Button>
                    </InputGroupAddon>
                  </InputGroup>

                  {/* Search start time */}
                  <InputGroup className="ml-2" style={{ maxWidth: "400px" }}>
                    <Input
                      type="date"
                      placeholder="Start Date"
                      value={initialDate.start}
                      onChange={(e) =>
                        setInitialDate({
                          ...initialDate,
                          start: e.target.value,
                        })
                      }
                    />
                    <Input
                      type="date"
                      placeholder="End Date"
                      value={initialDate.end}
                      onChange={(e) =>
                        setInitialDate({
                          ...initialDate,
                          end: e.target.value,
                        })
                      }
                    />

                    <InputGroupAddon addonType="prepend">
                      <Button
                        color="primary"
                        onClick={() => {
                          setFilterDate(initialDate);
                        }}
                      >
                        Search
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>

                  {/* Filter by Verify Status */}
                  <UncontrolledDropdown className="ml-2">
                    <DropdownToggle caret color="primary">
                      Status
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() => {
                          setStatus("");
                        }}
                      >
                        All
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          setStatus(false);
                        }}
                      >
                        Pending
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          setStatus(true);
                        }}
                      >
                        Completed
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </CardHeader>
              <div className="table-responsive">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Subject</th>
                      <th scope="col">Message</th>
                      <th scope="col">Date</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredEnquiries && filteredEnquiries?.length > 0 ? (
                      filteredEnquiries.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{trimContent(item.name, 20)}</td>
                          <td>{trimContent(item.email, 20)}</td>
                          <td>{trimContent(item.subject, 30)}</td>
                          <td>{trimContent(item.message, 25)}</td>
                          <td>{formatDate(item.createdAt)}</td>

                          <td>
                            <Badge color="" className="badge-dot mr-4">
                              <i
                                className={`bg-${
                                  item.status === true ? "success" : "warning"
                                }`}
                              />
                              {item.status === true ? "completed" : "pending"}
                            </Badge>
                          </td>
                          <td>
                            <Button
                              color="primary"
                              size="sm"
                              className="mr-2"
                              onClick={() => toggle(item)}
                            >
                              Reply
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7" className="text-center">
                          No data found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>

              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem>
                      <PaginationLink
                        className="disabled"
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setPageNumber(
                            pageNumber > 1 ? pageNumber - 1 : pageNumber
                          );
                        }}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>

                    {Array.from(
                      { length: enquiries?.totalPages },
                      (_, index) => (
                        <PaginationItem
                          key={index}
                          className={pageNumber === index + 1 ? "active" : ""}
                        >
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => {
                              e.preventDefault();
                              setPageNumber(index + 1);
                            }}
                          >
                            {index + 1}
                          </PaginationLink>
                        </PaginationItem>
                      )
                    )}

                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setPageNumber(
                            pageNumber < enquiries?.totalPages
                              ? pageNumber + 1
                              : pageNumber
                          );
                        }}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>

      <InquiryModel
        selectedInquiry={selectedInquiry}
        isOpen={isOpen}
        toggle={toggle}
      />
    </>
  );
};

export default Inquiry;
