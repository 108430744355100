import React, { useEffect, useState } from "react";
import { Card, CardHeader, Table, Container, Row, Button } from "reactstrap";
import Header from "components/Headers/Header.js";
import { getAllContent } from "services/api";
import { trimContent } from "services/help";
import { ContentModal } from "views/modal/ContentModal";
import { remove_p_br_span_Tags } from "services/help";

const Content = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [selectedContent, setSelectedContent] = useState(null);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const output = await getAllContent();
        console.log("get content output : ", output);
        setEmailTemplates(output?.contents || []);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle errors here, such as displaying an error message or setting a state variable
      }
    };

    fetchData(); // Call the fetchData function
  }, [loader]);

  const toggle = () => {
    setLoader((prev) => !prev);
    setIsOpen(!isOpen);
  };

  console.log("emailTemplates : ff", emailTemplates);

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row">
                  <h3 className="mb-0">Website Content Management</h3>
                </div>
              </CardHeader>
              <div className="table-responsive">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr style={{ maxWidth: "10wh" }}>
                      <th scope="col">SN</th>
                      <th scope="col">Topic</th>
                      <th scope="col">Slug</th>
                      <th scope="col">Content</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {emailTemplates.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{trimContent(item?.key, 30)}</td>
                        <td>{trimContent(item?.slug, 30)}</td>
                        <td>
                          {trimContent(remove_p_br_span_Tags(item?.value), 90)}
                        </td>
                        <td>
                          <Button
                            color="primary"
                            size="sm"
                            className="mr-2"
                            onClick={() => {
                              setSelectedContent(item);
                              toggle();
                            }}
                          >
                            Edit
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
      {/* Edit Template Modal */}
      <ContentModal data={selectedContent} isOpen={isOpen} toggle={toggle} />
    </>
  );
};

export default Content;
