import React, { useEffect, useState } from "react";
import { Card, CardHeader, Table, Container, Row, Button } from "reactstrap";
import Header from "components/Headers/Header.js";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import { getAllEmails } from "services/api";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { trimContent } from "services/help";
import { getAllFaq } from "services/api";
import { deleteFaq } from "services/api";
import { swalFire } from "services/help";
import { updateFaq } from "services/api";
import { FAQCreateModal } from "views/modal/FAQCreateModal";
import { FAQUpdateModal } from "views/modal/FAQUpdateModal";

const FAQ = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [templateContent, setTemplateContent] = useState("");
  const [faq, setFaq] = useState([]);
  const [selectedFaq, setSelectedFaq] = useState();
  const [isToggled, setIsToggled] = useState(false);
  const [task, setTask] = useState("update");
  const [loader, setLoader] = useState(false);

  const { navigate } = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const output = await getAllFaq();
        console.log("get faq output : ", output);

        if (!localStorage.getItem("accessToken")) {
          console.log("Logout from header");
          navigate("/auth/index");

          Swal.fire({
            position: "center",
            icon: "error",
            title: "Admin token expire",
            showConfirmButton: false,
            timer: 1500,
          });
        }

        setFaq(output);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [loader]);

  const toggle = () => {
    setLoader((prev) => !prev);
    setIsOpen(!isOpen);
  };

  const handleFaqDelete = async (faqId) => {
    try {
      await deleteFaq(faqId);
      setLoader((prev) => !prev);
      swalFire("success", "Faq Successfully Deleted");
    } catch (e) {
      console.log("Error : ", e);
    }
  };

  const handleEnableDisable = async (faqId, status) => {
    try {
      let data = {
        _id: faqId,
        isEnable: status === true ? "true" : "false",
      };
      await updateFaq(data);
      setLoader((prev) => !prev);
      swalFire("success", "Faq Updated Successfully");
    } catch (e) {
      console.log("Error : ", e);
    }
  };

  console.log("faq : ", faq);

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row">
                  <div className="col">
                    <h3 className="mb-0">FAQ Management</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      color="primary"
                      onClick={async () => {
                        setTask("add");
                        await toggle();
                      }}
                    >
                      + Add FAQ
                    </Button>
                  </div>
                </div>
              </CardHeader>
              <div className="table-responsive">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr style={{ maxWidth: "10wh" }}>
                      <th scope="col">ID</th>
                      <th scope="col">Quetion</th>
                      <th scope="col">Answer</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {faq && faq.length > 0 ? (
                      faq.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{trimContent(item.question, 25)}</td>
                          <td>{trimContent(item.answer, 30)}</td>
                          <td>
                            <Button
                              size="sm"
                              className="mr-2"
                              color={item?.isEnable ? "success" : "danger"}
                              onClick={async () => {
                                Swal.fire({
                                  title: "Are you sure?",
                                  text: "You want to change faq status!",
                                  icon: "warning",
                                  showCancelButton: true,
                                  confirmButtonColor: "#3085d6",
                                  cancelButtonColor: "#d33",
                                  confirmButtonText: "Yes, update it!",
                                }).then(async (result) => {
                                  if (result.isConfirmed) {
                                    await handleEnableDisable(
                                      item?._id,
                                      !item?.isEnable
                                    );
                                  }
                                });
                              }}
                            >
                              {item?.isEnable ? "On" : "Off"}
                            </Button>

                            <Button
                              color="primary"
                              size="sm"
                              className="mr-2"
                              onClick={() => {
                                setTask("update");
                                setSelectedFaq(item);
                                console.log("Email : ", item);
                                toggle();
                              }}
                            >
                              Edit
                            </Button>

                            <Button
                              color="danger"
                              size="sm"
                              className="mr-2"
                              onClick={async () => {
                                Swal.fire({
                                  title: "Are you sure?",
                                  text: "You won't be able to revert this faq!",
                                  icon: "warning",
                                  showCancelButton: true,
                                  confirmButtonColor: "#3085d6",
                                  cancelButtonColor: "#d33",
                                  confirmButtonText: "Yes, delete it!",
                                }).then(async (result) => {
                                  if (result.isConfirmed) {
                                    await handleFaqDelete(item._id);
                                  }
                                });
                              }}
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="9" className="text-center">
                          No data available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
      {/* Edit Template Modal */}
      {task === "update" && (
        <FAQUpdateModal faq={selectedFaq} isOpen={isOpen} toggle={toggle} />
      )}
      {task === "add" && <FAQCreateModal isOpen={isOpen} toggle={toggle} />}
    </>
  );
};

export default FAQ;
