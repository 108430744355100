// reactstrap components
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { getHeaderData } from "services/api";
import { Loader } from "variables/Loader";

const Header = () => {
  const [headerData, setHeaderData] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const output = await getHeaderData();
        if (!localStorage.getItem("accessToken")) {
          //  || !admin
          console.log("Logout from header");
          navigate("/auth/index");
        }

        setHeaderData(output?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle errors here, such as displaying an error message or setting a state variable
      } finally {
        setLoading(false);
      }
    };

    fetchData(); // Call the fetchData function
  }, []);

  return (
    <>
      {loading ? <Loader /> : null}

      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Soldout CopperCoin
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {headerData?.a} CUCC
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i className="fas fa-chart-bar" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      <span
                        className={`text-${
                          headerData?.g > 0 ? "success" : "danger"
                        } mr-2`}
                      >
                        <i
                          className={`fa fa-arrow-${
                            headerData?.g > 0 ? "up" : "down"
                          }`}
                        />{" "}
                        {headerData?.g}%
                      </span>{" "}
                      <span className="text-nowrap">Since last week</span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Total Stake
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {headerData?.b} CUCC
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <i className="fas fa-chart-pie" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      <span
                        className={`text-${
                          headerData?.h > 0 ? "success" : "danger"
                        } mr-2`}
                      >
                        <i
                          className={`fa fa-arrow-${
                            headerData?.h > 0 ? "up" : "down"
                          }`}
                        />{" "}
                        {headerData?.h}%
                      </span>{" "}
                      <span className="text-nowrap">Since last week</span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Sales
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {headerData?.c}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                          <i className="fas fa-users" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      <span
                        className={`text-${
                          headerData?.i > 0 ? "success" : "danger"
                        } mr-2`}
                      >
                        <i
                          className={`fa fa-arrow-${
                            headerData?.i > 0 ? "up" : "down"
                          }`}
                        />{" "}
                        {headerData?.i}%
                      </span>{" "}
                      <span className="text-nowrap">Since last week</span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Live price
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          ${headerData?.d}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                          <i className="fas fa-percent" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      <span
                        className={`text-${
                          headerData?.j > 0 ? "success" : "danger"
                        } mr-2`}
                      >
                        <i
                          className={`fa fa-arrow-${
                            headerData?.j > 0 ? "up" : "down"
                          }`}
                        />{" "}
                        {headerData?.j}%
                      </span>{" "}
                      <span className="text-nowrap">Since yesterday</span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
