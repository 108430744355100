import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import { fetchAdminProfile } from "services/api";

const AdminNavbar = (props) => {
  const [adminProfile, setAdminProfile] = useState(null);
  const [dp, setDp] = useState(null);
  const navigate = useNavigate();

  const getDp = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/download-dp?keydoc=${adminProfile?.kycDocument}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch image");
      }
      const dpBlob = await response.blob();
      return URL.createObjectURL(dpBlob);
    } catch (error) {
      console.error("Error fetching profile image:", error);
      return null;
    }
  };

  async function getAdminProfile() {
    try {
      const output = await fetchAdminProfile();
      console.log(output, "admin 98");
      setAdminProfile(output?.data?.admin);
    } catch (error) {
      console.error("Error fetching admin profile:", error);
    }
  }

  useEffect(() => {
    getAdminProfile();
  }, []);

  useEffect(() => {
    const fetchImage = async () => {
      if (adminProfile) {
        const dpUrl = await getDp();
        setDp(dpUrl);
      }
    };

    fetchImage();

    return () => {
      if (dp) {
        URL.revokeObjectURL(dp);
      }
    };
  }, [adminProfile]);

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
            <FormGroup className="mb-0">
              {/* <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input placeholder="Search" type="text" />
              </InputGroup> */}
              <div>
                <w3m-button />
              </div>
            </FormGroup>
          </Form>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span
                    className="avatar avatar-sm rounded-circle"
                    style={{
                      display: "inline-block",
                      width: "40px", // Adjust the size as needed
                      height: "40px", // Adjust the size as needed
                      overflow: "hidden",
                      position: "relative",
                      borderRadius: "50%",
                    }}
                  >
                    {dp && (
                      <img
                        alt="..."
                        src={dp}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: "50%",
                        }}
                      />
                    )}
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {adminProfile?.username}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>

              {/* <p>Wallet Connect 2</p> */}
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                <DropdownItem to="/admin/profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>My profile</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/auth/index");
                    localStorage.removeItem("accessToken");
                  }}
                >
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
