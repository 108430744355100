import { Link } from "react-router-dom";
// reactstrap components
import { NavbarBrand, Navbar, Container } from "reactstrap";
import blackLogo from "../../assets/img/brand/logo-black.svg";

const AdminNavbar = () => {
  return (
    <>
      <Navbar className="navbar-top navbar-horizontal navbar-dark" expand="md">
        <Container className="px-4">
          <NavbarBrand to="/" tag={Link}>
            {/* <img alt="..." src={require("../../assets/img/brand/logo.png")} /> */}
            <img alt="..." src={blackLogo} />
          </NavbarBrand>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
